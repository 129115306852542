<template>
  <v-data-table :headers="headers" :items="svzLeg" sort-by="position" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-btn color="primary" dark class="mb-2" style="margin-right:10px">
          <v-file-input hide-input truncate-length="15" @change="validateFile" v-model="csvFile" accept=".csv">
          </v-file-input>
        </v-btn>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>
                mdi-text-box-plus-outline
              </v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.shortName" label="Bezeichnung"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.ospDocType " label="OSP Dokumentenart"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="12">
                    <v-text-field v-model="editedItem.shortDesc" label="Kurzbeschreibung"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.personType" label="Personenart"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.position" label="Index"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-checkbox v-model="editedItem.visibleInOSP" label="Sichtbar im OSP"></v-checkbox>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-checkbox v-model="editedItem.active" label="Aktiv"></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                Abbrechen
              </v-btn>
              <v-btn color="primary" text @click="save">
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Wollen sie diesen Eintrag wirklich löschen?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDelete">Abbrechen</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">Löschen</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    props: {
      svzLeg: null,
    },
    data() {
      return {
        search: '',
        dialog: false,
        dialogDelete: false,
        headers: [{
            text: 'Bezeichnung',
            value: 'shortName'
          },
          {
            text: 'OSP Dokumentenart',
            value: 'ospDocType'
          },
          {
            text: 'Kurzbeschreibung',
            value: 'shortDesc'
          },
          {
            text: 'Personenart',
            value: 'personType'
          },
          {
            text: 'Index',
            value: 'position'
          },
          {
            text: 'Sichtbar im OSP',
            value: 'visibleInOSP'
          },
          {
            text: 'Aktiv',
            value: 'active'
          },
          {
            text: '',
            align: 'end',
            value: 'actions',
            sortable: false
          },
        ],
        editedIndex: -1,
        editedItem: {
          shortName: '',
          ospDocType: "",
          shortDesc: "",
          personType: '',
          position: "",
          visibleInOSP: "",
          active: "",
        },
        defaultItem: {
          shortName: '',
          ospDocType: "",
          shortDesc: "",
          personType: '',
          position: "",
          visibleInOSP: "",
          active: "",
        },
        csvFile: null,
      }
    },
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'Neuen Eintrag erstellen' : 'Eintrag bearbeiten'
      },
      formCompareIssuer(text) {
        return text ? "Ja" : "Nein"
      }
    },

    watch: {
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      },
    },

    methods: {
      validateFile() {
        var that = this
        var isValid = false
        var csvContent = null;
        if (this.csvFile) {
          var reader = new FileReader();
          reader.readAsText(this.csvFile, "UTF-8");
          reader.onload = function (evt) {
            csvContent = evt.target.result
            var lines = csvContent.split("\n");
            var length = 7
            isValid = true
            var i = 0
            while (i < lines.length) {
              lines[i] = lines[i].split(";");
              if ((lines[i].length == 1 && lines[i][0] == "") || lines[i] == "") {
                lines.splice(i, 1)
              } else {
                if (lines[i].length != length) {
                  isValid = false
                  break;
                }
                i++
              }
            }
            if (isValid) {
              that.parseFile(lines)
            } else {
              alert("This is no valid CSV File");
            }
          }
          reader.onerror = function () {
            alert("Error reading file");
          }
        }
      },
      parseFile(lines) {
        for (var i = 0; i < lines.length; i++) {
          var position = -1
          try {
            position = parseInt(lines[i][4])
          } catch {
            position = -1
          }
          var index = -1
          index = this.svzLeg.findIndex( x => x.position === position );
          if (index == -1) {
            var newItem = {
              shortName: lines[i][0],
              ospDocType: lines[i][1],
              shortDesc: lines[i][2],
              personType: lines[i][3],
              position: position,
              visibleInOSP: lines[i][5].toLowerCase().includes("ja"),
              active: lines[i][6].toLowerCase().includes("ja"),
            };
            this.svzLeg.push(newItem)
          } else {
              this.svzLeg[index].shortName = lines[i][0]
              this.svzLeg[index].ospDocType = lines[i][1]
              this.svzLeg[index].shortDesc = lines[i][2]
              this.svzLeg[index].personType = lines[i][3]
              this.svzLeg[index].position = position
              this.svzLeg[index].visibleInOSP = lines[i][5].toLowerCase().includes("ja")
              this.svzLeg[index].active = lines[i][6].toLowerCase().includes("ja")
          }
        }
      },
      editItem(item) {
        this.editedIndex = this.svzLeg.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem(item) {
        this.editedIndex = this.svzLeg.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm() {
        this.svzLeg.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save() {
        if (this.editedIndex > -1) {
          Object.assign(this.svzLeg[this.editedIndex], this.editedItem)
        } else {
          this.svzLeg.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>