<template>
  <v-row justify="center">
    <v-dialog v-model="show" fullscreen hide-overlay :retain-focus="false" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.stop="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Legitimationsdokument ansehen {{scan.id}}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

          </v-toolbar-items>
        </v-toolbar>
        <v-stepper v-model="e1">
          <v-stepper-header>
            <v-stepper-step :complete="e1 > 1" step="1">
              Abgleich mit Legitimationsdokument
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="e1 > 2" step="2">
              Abgleich mit OSP
            </v-stepper-step>
          </v-stepper-header>

          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card class="mb-12 step-one-holder-wrapper">
                <v-row>
                  <v-col col="12" md="5" xs="12">

                    <div class="step-one-column-one-wrapper">
                      <div v-if="scan.scanData" class="step-one-details-wrapper">
                        <div class="step-one-details-item-wrapper"
                          style="border-bottom:2px solid #666; margin-bottom:15px">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label>Bezeichnung</label>
                            </div>
                          </div>
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label>Wert</label>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <label>Geprüft</label>
                            </div>
                          </div>
                        </div>
                        <div v-if="scan.scanData.dtype" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label v-bind:for="'input-' + scan.scanData.dtype.fieldValue">Art der Legitimation</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-autocomplete v-model=scan.scanData.dtype.fieldValue :items="docs"
                                item-text="shortDesc" item-value="ospDocType" :return-object="false" hide-selected
                                v-bind:disabled=scan.scanData.dtype.checked label="Dokumentenart">
                              </v-autocomplete>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.dtype.checked
                                v-bind:disabled="scan.scanData.dtype.fieldValue == ''" class="step-one-checkbox"
                                color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div v-if="scan.scanData.documentNumber" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label v-bind:for="'input-' + scan.scanData.documentNumber.fieldValue">Nummer</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-text-field class="step-one-details-input"
                                v-bind:disabled=scan.scanData.documentNumber.checked :rules="inputRules"
                                v-model=scan.scanData.documentNumber.fieldValue
                                v-bind:id="'input-' + scan.scanData.documentNumber.fieldValue" type="text">
                              </v-text-field>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.documentNumber.checked
                                v-bind:disabled="scan.scanData.documentNumber.fieldValue == ''"
                                class="step-one-checkbox" color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div v-if="!isCompany && scan.scanData.firstNames" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label v-bind:for="'input-' + scan.scanData.firstNames.fieldValue">Vorname(n)</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-text-field class="step-one-details-input"
                                v-bind:disabled=scan.scanData.firstNames.checked :rules="inputRules"
                                v-model=scan.scanData.firstNames.fieldValue
                                v-bind:id="'input-' + scan.scanData.firstNames.fieldValue" type="text"></v-text-field>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.firstNames.checked
                                v-bind:disabled="scan.scanData.firstNames.fieldValue == ''" class="step-one-checkbox"
                                color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div v-if="!isCompany && scan.scanData.lastName" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label v-bind:for="'input-' + scan.scanData.lastName.fieldValue">Nachname</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-text-field class="step-one-details-input" :rules="inputRules"
                                v-bind:disabled=scan.scanData.lastName.checked v-model=scan.scanData.lastName.fieldValue
                                v-bind:id="'input-' + scan.scanData.lastName.fieldValue" type="text"></v-text-field>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.lastName.checked
                                v-bind:disabled="scan.scanData.lastName.fieldValue == ''" class="step-one-checkbox"
                                color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div v-if="!isCompany && scan.scanData.dateOfBirth" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label v-bind:for="'input-' + scan.scanData.dateOfBirth.fieldValue">Geburtsdatum</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-menu ref="birthdayMenu" v-model="birthdayMenu" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-bind:disabled=scan.scanData.dateOfBirth.checked
                                    v-model="scan.scanData.dateOfBirth.fieldValue" :rules="dateRules" v-bind="attrs" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker ref="birthdayPicker" v-model="dateOfBirth" @input="birthdayMenu = false">
                                </v-date-picker>
                              </v-menu>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.dateOfBirth.checked
                                v-bind:disabled="scan.scanData.dateOfBirth.fieldValue == ''" class="step-one-checkbox"
                                color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div v-if="!isCompany && scan.scanData.placeOfBirth" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label v-bind:for="'input-' + scan.scanData.placeOfBirth.fieldValue">Geburtsort</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-text-field class="step-one-details-input"
                                v-bind:disabled=scan.scanData.placeOfBirth.checked :rules="inputRules"
                                v-model=scan.scanData.placeOfBirth.fieldValue
                                v-bind:id="'input-' + scan.scanData.placeOfBirth.fieldValue" type="text"></v-text-field>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.placeOfBirth.checked
                                v-bind:disabled="scan.scanData.placeOfBirth.fieldValue == ''" class="step-one-checkbox"
                                color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div v-if="scan.scanData.nationality" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label
                                v-bind:for="'input-' + scan.scanData.nationality.fieldValue">Staatsangehörigkeit</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-autocomplete class="step-one-details-input"
                                v-model="scan.scanData.nationality.fieldValue" :items="countries" item-text="kurzform"
                                item-value="icao3" v-bind:disabled="scan.scanData.nationality.checked"
                                label="Nationalität">
                              </v-autocomplete>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.nationality.checked
                                v-bind:disabled="scan.scanData.nationality.fieldValue == ''" class="step-one-checkbox"
                                color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div v-if="scan.scanData.issuer" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label v-bind:for="'input-' + scan.scanData.issuer.fieldValue">Ausstellende
                                Behörde</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-text-field class="step-one-details-input" v-bind:disabled=scan.scanData.issuer.checked
                                :rules="inputRules" v-model=scan.scanData.issuer.fieldValue
                                v-bind:id="'input-' + scan.scanData.issuer.fieldValue" type="text"></v-text-field>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.issuer.checked
                                v-bind:disabled="scan.scanData.issuer.fieldValue == ''" class="step-one-checkbox"
                                color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div v-if="scan.scanData.issuerCountry" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label v-bind:for="'input-' + scan.scanData.issuerCountry.fieldValue">Ausstellende Behörde
                                (Land)</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-autocomplete class="step-one-details-input"
                                v-model="scan.scanData.issuerCountry.fieldValue" :items="countries" item-text="kurzform"
                                item-value="icao3" v-bind:disabled="scan.scanData.issuerCountry.checked"
                                label="Ausstellungsland"></v-autocomplete>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.issuerCountry.checked
                                v-bind:disabled="scan.scanData.issuerCountry.fieldValue == ''" class="step-one-checkbox"
                                color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div v-if="scan.scanData.issuedAt" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label v-bind:for="'input-' + scan.scanData.issuedAt.fieldValue">Ausstellungsdatum</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-menu top ref="issuedAtMenu" v-model="issuedAtMenu" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-bind:disabled=scan.scanData.issuedAt.checked v-model="scan.scanData.issuedAt.fieldValue"
                                    v-bind="attrs" :rules="dateRules" v-on="on"></v-text-field>
                                </template>
                                <v-date-picker ref="issuedAtPicker" v-model="issuedAt"  @input="issuedAtMenu = false">
                                </v-date-picker>
                              </v-menu>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.issuedAt.checked
                                v-bind:disabled="scan.scanData.issuedAt.fieldValue == ''" class="step-one-checkbox"
                                color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                        <div v-if="scan.scanData.validUntil" class="step-one-details-item-wrapper">
                          <div class="step-one-details-label-outer-wrapper">
                            <div class="step-one-details-item-label-inner-wrapper">
                              <label v-bind:for="'input-' + scan.scanData.issuerCountry.fieldValue">Ablaufdatum</label>
                            </div>
                          </div>
                          <div class="step-one-details-input-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-menu top ref="validUntilMenu" v-model="validUntilMenu" :close-on-content-click="false"
                                transition="scale-transition" offset-y min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text-field v-bind:disabled=scan.scanData.validUntil.checked
                                    v-model="scan.scanData.validUntil.fieldValue" :rules="dateRules" v-bind="attrs" v-on="on">
                                  </v-text-field>
                                </template>
                                <v-date-picker ref="validUntilPicker" v-model="validUntil"  @input="validUntilMenu = false">
                                </v-date-picker>
                              </v-menu>
                            </div>
                          </div>
                          <div class="step-one-details-checkbox-outer-wrapper">
                            <div class="step-one-details-item-inner-wrapper">
                              <v-checkbox v-model=scan.scanData.validUntil.checked
                                v-bind:disabled="scan.scanData.validUntil.fieldValue == '' && (scan.scanData.dtype.fieldValue != 'GU' && scan.scanData.dtype.fieldValue != 'HR') "
                                class="step-one-checkbox" color="#ff0000" hide-details></v-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-col>
                  <v-col col="12" md="7" xs="12">
                    <div v-if="scan.security && scan.security.securityStatus != ''" class="step-one-column-one-wrapper">
                      <div class="step-one-details-wrapper" :style="securityTextStyle(scan.security.securityStatus)">
                        <div class="step-one-details-item-wrapper">
                          <div style="width:100%" class="step-one-details-input-outer-wrapper">
                            <div v-if="scan.security.securityStatus" class="step-one-details-item-inner-wrapper">
                              <label
                                v-bind:for="'input-' + scan.security.securityStatus + '-hint'">{{securityStatusTexts[scan.security.securityStatus].text}}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="step-one-column-two-wrapper">
                      <v-container style="display:flex; justify-content:center">
                        <v-slide-group class="pa-4" active-class="success" show-arrows center-active>
                          <v-slide-item @click="activeSlide = slide" v-for="slide in scan.images" :key="slide.id">
                            <v-card @click="activeSlide = slide" style="display:flex" class="ma-4" height="auto"
                              width="100">
                              <v-img v-bind:src="slide.processedImage" style="margin-top: auto; margin-bottom: auto;"
                                class="grey lighten-2">
                                <template v-slot:placeholder>
                                  <v-row class="fill-height ma-0" align="center" justify="center">
                                    <v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
                                  </v-row>
                                </template>
                              </v-img>
                            </v-card>

                          </v-slide-item>
                        </v-slide-group>

                      </v-container>
                      <v-container style="display:flex; justify-content:center">
                        <img v-if="activeSlide" v-bind:src="activeSlide.processedImage"
                          alt="">
                      </v-container>
                    </div>
                  </v-col>
                </v-row>


              </v-card>

              <div class="stepper-button-holder">
                <v-btn color="primary" @click="show = false">
                  Schließen
                </v-btn>

                <v-btn color="primary" @click="save(1)" v-bind:disabled=isDisabled>
                  Weiter
                </v-btn>
              </div>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-dialog v-model="multipleNationalitiesDialog" width="500">

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Mehrere Staatsangehörigkeiten
                  </v-card-title>

                  <v-card-text>
                    In OSPlus sind zwei Staatsangehörigkeiten hinterlegt. Bitte auf Aktualität prüfen!
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="multipleNationalitiesDialog = false">
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-card class="mb-12 step-two-cardview-wrapper" width="930px">
                <div class="step-two-details-wrapper">
                  <v-progress-linear indeterminate v-show="isLoadingStepTwo"></v-progress-linear>
                  <template>
                    <v-data-table :headers="headers" :items="inputRows" :items-per-page="-1" class="elevation-1">
                      <template v-slot:top>
                        <v-toolbar flat>
                          <v-card-title>Personen-Nr:</v-card-title>
                          <v-text-field v-model="personNumber" :rules="inputRules">
                          </v-text-field>
                          <v-spacer></v-spacer>
                          <v-btn color="primary" dark class="mb-2 step-two-search-button"
                            @click="getPerson(personNumber)">
                            Suchen
                          </v-btn>
                          <v-btn color="primary" dark class="mb-2 step-two-search-button"
                            @click.stop=" multipleFindsDialog = true ">
                            Neue Auswahl
                          </v-btn>
                        </v-toolbar>
                      </template>
                    </v-data-table>
                  </template>
                </div>
              </v-card>

              <div class="stepper-button-holder">
                <v-btn color="primary" @click="e1 = 1; this.continueEnabled = true">
                  Zurück
                </v-btn>
              </div>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <MultipleFindsDialog v-model=multipleFindsDialog :persons="ospPerson" />
    <NotFoundDialog v-model=notFoundDialog />
  </v-row>
</template>

<script>
  //import {Carousel3d,Slide} from "vue-carousel-3d";
  import MultipleFindsDialog from "./MultipleFindsDialog"
  import NotFoundDialog from "./NotFoundDialog"

  export default {
    data() {
      return {
        isLoadingStepTwo: false,
        dateOfBirth : null,
        issuedAt : null,
        validUntil : null,
        legitimizeEnabled: false,
        personNumber: "",
        imagesChanged: false,
        errorDialog: false,
        birthdayMenu: false,
        issuedAtMenu: false,
        multipleNationalitiesDialog: false,
        validUntilMenu: false,
        useDefaultUI: true,
        activeSlide: null,
        continueEnabled: true,
        multipleFindsDialog: false,
        notFoundDialog: false,
        dialog: false,
        dateRules: [
          v => v.length <= 10 || 'Datumsformat: DD.MM.YYYY',
          v => this.checkDate(v) || 'Das ist kein valides Datum',
        ],
        inputRules: [
          v => v.length <= 100 || 'Maximal 100 Zeichen',
          v => /[a-z0-9]/i.test(v) || "Mindestens ein Zeichen"
        ],
        e1: 1,
        headers: [{
            text: 'Bezeichnung',
            align: 'start',
            sortable: false,
            value: 'label',
          },
          {
            text: 'Daten auf Legitimationsdokument',
            value: 'scanValue'
          },
          {
            text: 'Daten aus OSPlus',
            value: 'ospValue'
          },
        ],
        inputRows: [{
            label: "Personen-Nr",
            scanValue: "",
            ospValue: "",
          },
          {
            label: "Titel",
            scanValue: "",
            ospValue: "",
          },
          {
            label: "Name",
            scanValue: "",
            ospValue: "",
          },
          {
            label: "Vorname",
            scanValue: "",
            ospValue: "",
          },
          {
            label: "Weitere Vornamen",
            scanValue: "",
            ospValue: "",
          },
          {
            label: "Geburtsdatum",
            scanValue: "",
            ospValue: "",
          },
          {
            label: "Geburtsort",
            scanValue: "",
            ospValue: "",
          },
          {
            label: "Staatsangehörigkeit",
            scanValue: "",
            ospValue: "",
          },
          {
            label: "2. Staatsangehörigkeit",
            scanValue: "",
            ospValue: "",
          },
        ]
      }
    },
    props: {
      value: Boolean,
      scan: null,
      countries: null,
      isCompany: null,
      docs: null
    },
    computed: {
      securityStatusTexts(){
        return this.$store.getters.getSecurityStatusTexts
      },
      displayPerson() {
        return this.$store.getters.getDisplayOspperson
      },
      ospPerson: {
        get() {
          return this.$store.getters.getOspperson
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      formatBirthday: {
        get: function () {
          if (!this.birthdayDate || this.birthdayDate.includes("undefined")) {
            return ""
          }
          return this.birthdayDate
        },
        set: function (value) {
          if (this.checkDate(value)) {
            const [day, month, year] = value.split('.')
            if (!year || !month || !day) {
              this.birthdayDate = undefined
            } else {
              this.birthdayDate = `${day}.${month}.${year}`
            }
          }
        }
      },
      formatIssuedAt: {
        get: function () {
          if (!this.issuedAtDate || this.issuedAtDate.includes("undefined")) return ""
          return this.issuedAtDate
        },
        set: function (value) {
          if (this.checkDate(value)) {
            const [day, month, year] = value.split('.')
            if (!year || !month || !day) {
              this.issuedAtDate = undefined
            } else {
              this.issuedAtDate = `${day}.${month}.${year}`
            }
          }
        }
      },
      formatValidUntil: {
        get: function () {
          if (!this.validUntilDate || this.validUntilDate.includes("undefined")) {
            return ""
          }
          return this.validUntilDate 
        },
        set: function (value) {
          if (this.checkDate(value)) {
            const [day, month, year] = value.split('.')
            if (!year || !month || !day) {
              this.validUntilDate = ""
            } else {
              this.validUntilDate = `${day}.${month}.${year}`
            }
          }
        }
      },
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      canContinue() {
        return this.$store.getters.getActiveError
      },
      canClose() {
        return this.$store.getters.getActiveSuccess
      },
      isDisabled() {
        if (!this.continueEnabled) {
          return true;
        }
        for (var dataField in this.scan.scanData) {
          if (!Object.prototype.hasOwnProperty.call(this.scan.scanData, dataField) || !this.scan.scanData[dataField] ||
            !Object.prototype.hasOwnProperty.call(this.scan.scanData[dataField], "checked")) {
            continue
          }
          if (dataField != "id" && this.scan.scanData[dataField] && !this.scan.scanData[dataField].checked) {
            return true
          }
        }
        return false

        /*if (this.scan.scanData.documentNumber) {
          if (!this.scan.scanData.documentNumber.checked) {
            return false
          }
        }
        if (this.scan.scanData.dateOfBirth) {
          if (!this.scan.scanData.dateOfBirth.checked) {
            return false
          }
        }
        if (this.scan.scanData.firstNames) {
          if (!this.scan.scanData.firstNames.checked) {
            return false
          }
        }
        if (this.scan.scanData.issuedAt) {
          if (!this.scan.scanData.issuedAt.checked) {
            return false
          }
        }
        if (this.scan.scanData.issuer) {
          if (!this.scan.scanData.issuer.checked) {
            return false
          }
        }
        if (this.scan.scanData.issuerCountry) {
          if (!this.scan.scanData.issuerCountry.checked) {
            return false
          }
        }
        if (this.scan.scanData.lastName) {
          if (!this.scan.scanData.lastName.checked) {
            return false
          }
        }
        if (this.scan.scanData.nationality) {
          if (!this.scan.scanData.nationality.checked) {
            return false
          }
        }
        if (this.scan.scanData.placeOfBirth) {
          if (!this.scan.scanData.placeOfBirth.checked) {
            return false
          }
        }
        if (this.scan.scanData.validUntil) {
          if (!this.scan.scanData.validUntil.checked) {
            return false
          }
        }
        if (this.scan.scanData.dtype) {
          if (!this.scan.scanData.dtype.checked) {
            return false
          }
        }
        return true*/
      }
    },
    watch: {
      // e1(index) {
      //   if (index == 1) {
      //     // Here you would put something to happen when dialog opens up
      //     setTimeout(() => {
      //       this.$refs['my-carousel'].$el.style.height = 'auto';
      //     }, 100);
      //   } else {
      //   }
      // },
      displayPerson(person) {
        if (person.personNumber) {
          this.legitimizeEnabled = true
        } else {
          this.legitimizeEnabled = false
        }
        this.personNumber = person.personNumber
        this.inputRows[0].ospValue = person.personNumber
        this.inputRows[1].ospValue = person.title
        this.inputRows[2].ospValue = person.lastName
        this.inputRows[3].ospValue = person.firstName
        this.inputRows[4].ospValue = person.additionalFirstNames
        this.inputRows[5].ospValue = person.birthday
        this.inputRows[6].ospValue = person.placeOfBirth
        this.inputRows[7].ospValue = this.countries.find(x => x.ospnat == person.nationality) ? this.countries.find(x =>
          x.ospnat == person.nationality).nationalitaet : ""
        this.inputRows[8].ospValue = this.countries.find(x => x.ospnat == person.otherNationality) ? this.countries
          .find(x =>
            x.ospnat == person.otherNationality).nationalitaet : ""

        if (person.otherNationality.length > 0) {
          this.multipleNationalitiesDialog = true
        }
      },
      ospPerson(persons) {
        if (persons.length == 0) {
          this.notFoundDialog = true
        } else if (persons.length == 1) {
          this.isLoadingStepTwo = true
          this.$store.dispatch("loadDisplayOspperson", persons[0].personNumber).then(() => {
            this.isLoadingStepTwo = false
          })
        } else {
          this.multipleFindsDialog = true
        }
      },
      scan(value) {
       
        this.activeSlide = null
        value.images.sort((a, b) => (parseInt(a.createdAt) > parseInt(b.createdAt)) ? 1 : ((parseInt(b.createdAt) >
          parseInt(a.createdAt)) ? -1 : 0))
          var today = (new Date()).toISOString().substr(0,10)
          var startDate = new Date()
          if(this.checkDate(this.scan.scanData.dateOfBirth.fieldValue)){
          const [day, month, year] = this.scan.scanData.dateOfBirth.fieldValue.split('.')
          startDate.setFullYear(year, month-1, day)
            this.dateOfBirth = startDate.toISOString().substr(0,10)
        }else{
            this.dateOfBirth = today
        }

        if(this.checkDate(this.scan.scanData.issuedAt.fieldValue)){
          const [day, month, year] = this.scan.scanData.issuedAt.fieldValue.split('.')
          startDate.setFullYear(year, month-1, day)
            this.issuedAt =startDate.toISOString().substr(0,10)
        }else{
            this.issuedAt = today
        }

        if(this.checkDate(this.scan.scanData.validUntil.fieldValue)){
          
          const [day, month, year] = this.scan.scanData.validUntil.fieldValue.split('.')
          startDate.setFullYear(year, month-1, day)
            this.validUntil =startDate.toISOString().substr(0,10)
        }else{
            this.validUntil = today
        }
      },
      // eslint-disable-next-line no-unused-vars
      dateOfBirth(val) {
         this.scan.scanData.dateOfBirth.fieldValue  = this.formatDate(val)
      },
            // eslint-disable-next-line no-unused-vars
      validUntil(val) {
         this.scan.scanData.validUntil.fieldValue = this.formatDate(val)
      },
            // eslint-disable-next-line no-unused-vars
      issuedAt(val) {
        //console.log("watching issued at...")
         this.scan.scanData.issuedAt.fieldValue  = this.formatDate(val)
      },
      birthdayMenu(val) {
        val && setTimeout(() => (this.$refs.birthdayPicker.activePicker = 'YEAR'))
      },
      issuedAtMenu(val) {
        val && setTimeout(() => (this.$refs.issuedAtPicker.activePicker = 'YEAR'))
      },
      validUntilMenu(val) {
        val && setTimeout(() => (this.$refs.validUntilPicker.activePicker = 'YEAR'))
      },
      canContinue(wait) {
        if (!wait) {
          this.$store.dispatch("changeActiveError", true)
          this.e1 = 2
        }
      },
      canClose(wait) {
        if (!wait) {
          this.$store.dispatch("changeActiveSuccess", true)
          this.show = false
        }
      },
      e1(e1) {
        if (e1 == 2) {
          //var nationalityCode = this.countries.find(x => x.icao3 == this.scan.scanData.nationality.fieldValue).ospnat
          var nationality = this.countries.find(x => x.icao3 == this.scan.scanData.nationality.fieldValue).nationalitaet
          this.inputRows.forEach(element => {
            element.ospValue = ""
          });
          this.inputRows[2].scanValue = this.scan.scanData.lastName.fieldValue
          var firstNames = this.scan.scanData.firstNames.fieldValue.split(" ")
          this.inputRows[3].scanValue = firstNames.join(" ")
          /*     if(firstNames.length > 1){
                 this.inputRows[4].scanValue = firstNames[1]
               }*/
          this.inputRows[5].scanValue = this.scan.scanData.dateOfBirth.fieldValue
          this.inputRows[6].scanValue = this.scan.scanData.placeOfBirth.fieldValue
          this.inputRows[7].scanValue = nationality
          this.$store.dispatch("loadOspperson", {
            firstName: this.scan.scanData.firstNames.fieldValue,
            lastName: this.scan.scanData.lastName.fieldValue,
            birthday: this.scan.scanData.dateOfBirth.fieldValue
          })
          /*this.$store.dispatch("loadOspperson", {
            firstName: "Erika",
            lastName: "LegiScan",
            nationality: "004",
            birthday: "12.08.1964"
          })*/
          //this.$store.dispatch("loadOspperson", {firstName: "Erika", lastName: "", nationality: "", birthday: ""})
        }
      },
      show(visible) {
        if (visible) {
          // Here you would put something to happen when dialog opens up
          this.personNumber = ""
          this.legitimizeEnabled = false
          this.$store.dispatch("loadImages", this.scan)
          this.e1 = 1
        } 
      }
    },
    methods: {
      formatDate (date) {
        if (!date) return null

        const [year, month, day] = date.split('-')
        return `${day}.${month}.${year}`
      },
      imageSaved(event) {
        this.scan.imagesChanged = true
        this.activeSlide.processedImage = event
      },
      getPerson(personNumber) {
        this.isLoadingStepTwo = true
        this.$store.dispatch("loadDisplayOspperson", personNumber).then(() => {
          this.isLoadingStepTwo = false
        })
        this.personNumber = personNumber
      },
      legitimize() {
        var data = {
          scanID: this.scan.id,
          ospNumber: this.personNumber
        }
        this.isLoadingStepTwo = true
        this.legitimizeEnabled = false
        this.$store.dispatch("legitimize", data).then(() => {
          this.isLoadingStepTwo = false
          this.legitimizeEnabled = true

        })
      },
      securityTextStyle(statusText) {
        return "box-shadow: 0 0 0.4em" + (this.securityStatusTexts[statusText] ? this.securityStatusTexts[statusText]
          .color : "black")
      },
      checkDate(date) {
        var re =
          /^(?:(?:31(\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\.)(?:0?[1,3-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
        var match = date.match(re);
        if (match || date.trim() === '') {
          return true
        }
        return false
      },
      saveBirthday(birthdayDate) {
        this.$refs.birthdayMenu.save(birthdayDate)
      },
      saveIssuedAt(issuedAtDate) {
        this.$refs.issuedAtMenu.save(issuedAtDate)
      },
      saveValidUntil(validUntilDate) {
        this.$refs.validUntilMenu.save(validUntilDate)
      },
      xYearsFromNow(x) {
        var xYearsFromNow = new Date();
        xYearsFromNow.setFullYear(xYearsFromNow.getFullYear() + x);
        return xYearsFromNow.toISOString().substr(0, 10)
      },
      save(index) {
        this.personNumber = ""

        this.continueEnabled = false
        this.$store.dispatch("editScan", {
          scan: this.scan,
          imagesChanged: this.scan.imagesChanged
        }).then(() => {
          this.continueEnabled = true
        })
        this.imagesChanged = false
        if (index == 2) {
          this.show = false
        }
      },
      // eslint-disable-next-line no-unused-vars
      saved(url) {
        this.imagesChanged = true
      },
      onAfterSlideChange(index) {
        this.activeSlide = index
      },
      // eslint-disable-next-line no-unused-vars
      onBeforeSlideChange(index) {
      },
      // eslint-disable-next-line no-unused-vars
      onLastSlide(index) {
      }
    },
    components: {
      //Carousel3d,
      //Slide,
      MultipleFindsDialog,
      NotFoundDialog,
    },
  }
</script>

<style scoped>
  .image-thumps:hover {
    box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
  }

  .step-one-image-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 15px;
    margin: 10px;
  }

  .step-one-details-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 15px;
    margin: 10px;
  }

  .step-one-column-two-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 15px;
    margin: 10px;
  }

  .step-one-column-two-wrapper:hover,
  .step-one-details-wrapper:hover,
  .step-one-image-wrapper:hover {
    box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
  }

  .step-one-details-item-wrapper {
    display: flex;
  }

  .step-one-details-label-outer-wrapper {
    display: flex;
    width: 40%;
  }

  .step-one-details-input-outer-wrapper {
    display: flex;
    justify-content: center;
    width: 40%;
  }

  .step-one-details-checkbox-outer-wrapper {
    display: flex;
    justify-content: flex-end;
    width: 20%;
  }

  .step-one-checkbox {
    margin-top: 0px;
  }

  .step-one-details-item-label-inner-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: left;
  }

  .step-one-details-item-inner-wrapper {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .step-one-column-two-wrapper {
    margin-left: 15px;
    margin-right: 15px;
  }

  .step-one-column-two-wrapper img {
    max-width: 100%;
    width: 100%;
    height: auto;
    margin-top: auto;
    margin-bottom: auto;
  }

  .step-two-cardview-wrapper {
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  }

  .step-two-cardview-wrapper:hover {
    box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
  }

  .step-two-search-button {
    margin: 5px;
  }

  .stepper-button-holder {
    display: flex;
    justify-content: flex-end;
  }

  .stepper-button-holder button {
    margin-right: 5px;
    margin-left: 5px;
  }

  .carousel-3d-container figcaption {
    text-align: center;
    color: white;
    font-size: bold;
    margin-top: 10px;
  }

  .carousel-3d-slide {
    background-color: #666;
  }

  .step-one-image-button-wrapper {
    display: flex;
    justify-content: center;
    flex-flow: row wrap;
  }
</style>

<style>
  .v-input--is-disabled .v-text-field__slot input,
  .v-input--is-disabled .v-select__slot input,
  .v-input--is-disabled .v-select__slot label {
    color: #666 !important;
    opacity: 0.7;
  }
</style>