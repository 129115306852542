<template>
  <div>
    <v-data-table :headers="headers" :items="countries" item-key="name" class="elevation-1" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line hide-details>
          </v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                Neuer Eintrag
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.icao3" label="ICAO3"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.icao2" label="ICAO2"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.iso_3166_A2" label="ISO_3166_A2"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.iso_3166_A3" label="ISO_3166_A3"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.kfz" label="KFZ"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.vollform" label="BZNG_VOLLFORM"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.kurzform" label="BZNG_KURZFORM"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.englisch" label="BZNG_ENGLISCH"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.nationalitaet" label="NATIONALITAET"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.abkuerzung" label="ABKUERZUNG"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.ospnat" label="OSP_NAT"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.osplkz" label="OSP_LKZ"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.osplap" label="OSP_LAP"></v-text-field>
                  </v-col>
                </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  Abbrechen
                </v-btn>
                <v-btn color="primary" text @click="save">
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-btn @click="history()" icon>
            <v-icon small class="mr-2">
              fa-history
            </v-icon>
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title class="headline">Wollen Sie diesen Eintrag wirklich löschen?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDelete">Abbrechen</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">löschen</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
        </v-toolbar>

      </template>
      <template v-slot:no-data>
        <v-data-table item-key="name" class="elevation-1" loading loading-text="Daten werden geladen"
          :hide-default-footer="true"></v-data-table>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <v-btn @click="historyItem(item)" icon>
          <v-icon small class="mr-2">
            fa-history
          </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <app-revision-dialog :dialog="revisionEditDialog" @save="saveWithRevision"></app-revision-dialog>
    <app-revision-dialog :dialog="revisionDeleteDialog" @save="deleteWithRevision"></app-revision-dialog>
  </div>
</template>

<script>
  import AppRevisionDialog from "./Revision/RevisionDialog.vue";

  export default {
    components: {AppRevisionDialog},
    data() {
      return {
        dialog: false,
        revisionEditDialog: false,
        revisionDeleteDialog:false,
        dialogDelete: false,
        search: '',
        editedIndex: -1,
        editedItem: {
          abkuerzung: null,
          created_at: null,
          englisch: null,
          icao2: null,
          icao3: null,
          id: null,
          iso_3166_A2: null,
          iso_3166_A3: null,
          kfz: null,
          kurzform: null,
          nationalitaet: null,
          osplap: null,
          osplkz: null,
          ospnat: null,
          vollform: null,
        },
        defaultItem: {
          abkuerzung: null,
          created_at: null,
          englisch: null,
          icao2: null,
          icao3: null,
          id: null,
          iso_3166_A2: null,
          iso_3166_A3: null,
          kfz: null,
          kurzform: null,
          nationalitaet: null,
          osplap: null,
          osplkz: null,
          ospnat: null,
          vollform: null,
        },
        headers: [{
            text: 'ICAO3 ',
            align: 'start',
            value: 'icao3',
          },
          {
            text: 'ICAO2',
            value: 'icao2'
          },
          {
            text: 'ISO_3166_A2',
            value: 'iso_3166_A2'
          },
          {
            text: 'ISO_3166_A3',
            value: 'iso_3166_A3'
          },
          {
            text: 'KFZ',
            value: 'kfz'
          },
          {
            text: 'BZNG_VOLLFORM',
            value: 'vollform'
          },
          {
            text: 'BZNG_KURZFORM',
            value: 'kurzform'
          },
          {
            text: 'BZNG_ENGLISCH',
            value: 'englisch'
          },
          {
            text: 'NATIONALITAET',
            value: 'nationalitaet'
          },
          {
            text: 'ABKUERZUNG',
            value: 'abkuerzung'
          },
          {
            text: 'OSP_NAT',
            value: 'ospnat'
          },
          {
            text: 'OSP_LKZ',
            value: 'osplkz'
          },
          {
            text: 'OSP_LAP',
            value: 'osplap'
          },
          {
            text: '',
            value: 'actions',
            sortable: false
          },
        ],
      }
    },
    watch: {
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      },
    },
    computed: {
      countries() {
        return this.$store.getters.getCountries
      },
      formTitle() {
        return this.editedIndex === -1 ? 'Neuen Eintrag erstellen' : 'Eintrag bearbeiten'
      },
    },
    created() {
      this.initialize()
    },
    methods: {
      history() {
        this.$router.push({
          name: 'Revisions',
          params: {
            className: "Country"
          },
          query: {
            caller: this.$route.name
          }
        })
      },
      historyItem(item) {
        this.$router.push({
          name: 'RevisionForId',
          params: {
            className: "Country",
            id: item.id
          },
          query: {
            caller: this.$route.name
          }
        })
      },
      initialize() {
        this.$store.dispatch("loadCountries")
      },
      editItem(item) {
        this.editedIndex = this.countries.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem(item) {
        this.editedIndex = this.countries.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm() {
        this.revisionDeleteDialog = true
      },
      deleteWithRevision(revision){
        this.$store.dispatch("deleteCountry", {country:this.countries[this.editedIndex], revision:revision})
        this.countries.splice(this.editedIndex, 1)
        this.closeDelete()
        this.revisionDeleteDialog = false
      },
      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save() {
        this.revisionEditDialog = true
      },
      saveWithRevision(revision){
        if (this.editedIndex > -1) {
          Object.assign(this.countries[this.editedIndex], this.editedItem)
          this.$store.dispatch("editCountry", {country:this.editedItem,revision:revision})
        } else {
          this.countries.push(this.editedItem)
          this.$store.dispatch("addCountry", {country:this.editedItem,revision:revision})
        }
        this.close()
        this.revisionEditDialog = false
      }
    }
  }
</script>