<template>
  <div>
    <v-data-table :headers="headers" :items="items" sort-by="name" class="elevation-1" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line hide-details>
          </v-text-field>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                <v-icon>mdi-account-plus</v-icon>
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field v-model="editedItem.firstname" label="Vorname"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field v-model="editedItem.lastname" label="Nachname"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field v-model="editedItem.email" label="E-Mail"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field v-model="editedItem.password" label="Passwort"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-text-field v-model="editedItem.company" label="Unternehmen"></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="12" md="6">
                      <v-combobox v-model="editedItem.role" :items="roles" label="Rolle"
                                  placeholder="Feld auswählen..."></v-combobox>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="close">
                  Abbrechen
                </v-btn>
                <v-btn color="primary" text @click="save">
                  Speichern
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-btn @click="history()" icon>
            <v-icon small class="mr-2">
              fa-history
            </v-icon>
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="headline">Wollen Sie diesen Eintrag wirklich löschen?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm">Löschen</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <v-btn @click="historyItem(item)" icon>
          <v-icon small class="mr-2">
            fa-history
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:no-data>
        <v-data-table item-key="name" class="elevation-1" loading loading-text="Daten werden geladen"
                      :hide-default-footer="true"></v-data-table>
      </template>

    </v-data-table>
    <app-revision-dialog :dialog="revisionEditDialog" @save="saveWithRevision"></app-revision-dialog>
    <app-revision-dialog :dialog="revisionDeleteDialog" @save="deleteWithRevision"></app-revision-dialog>

  </div>

</template>

<script>
import AppRevisionDialog from "./Revision/RevisionDialog.vue";

export default {
  components: {AppRevisionDialog},
  data: () => ({
    revisionEditDialog: false,
    revisionDeleteDialog:false,
    dialog: false,
    search: "",
    dialogDelete: false,
    headers: [{
      text: 'Vorname',
      align: 'start',
      value: 'firstname',
    },
      {
        text: 'Nachname',
        value: 'lastname'
      },
      {
        text: 'E-Mail',
        value: 'email'
      },
      {
        text: 'Unternehmen',
        value: 'company'
      },
      {
        text: 'Rolle',
        value: 'role'
      },
      {
        text: '',
        value: 'actions',
        sortable: false
      },
    ],
    roles: [
      "ADMIN", "OPERATOR", "MONITORING"
    ],
    editedIndex: -1,
    editedItem: {
      firstname: '',
      lastname: "",
      email: "",
      password: "",
      company: "",
      role: ""
    },
    defaultItem: {
      firstname: '',
      lastname: "",
      email: "",
      password: "",
      company: "",
      role: ""
    },
  }),

  computed: {
    items() {
      return this.$store.getters.getUsers
    },
    formTitle() {
      return this.editedIndex === -1 ? 'Neuer Nutzer' : 'Nutzer bearbeiten'
    },
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    this.initialize()
  },
  methods: {
    history() {
      this.$router.push({
        name: 'Revisions',
        params: {
          className: "User"
        },
        query: {
          caller: this.$route.name
        }
      })
    },
    historyItem(item) {
      this.$router.push({
        name: 'RevisionForId',
        params: {
          className: "User",
          id: item.id
        },
        query: {
          caller: this.$route.name
        }
      })
    },
    initialize() {
      this.$store.dispatch("loadUsers")
    },

    editItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.revisionDeleteDialog = true
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      this.revisionEditDialog = true
    },
    saveWithRevision(revision){
      if (this.editedIndex > -1) {
        Object.assign(this.items[this.editedIndex], this.editedItem)
        this.$store.dispatch("editUser",{user:this.editedItem,revision:revision} )
      } else {
        this.items.push(this.editedItem)
        this.$store.dispatch("addUser", {user:this.editedItem,revision:revision} )
      }
      this.close()
      this.revisionEditDialog = false
    },
    deleteWithRevision(revision){
      this.$store.dispatch("deleteUser", {user:this.editedItem,revision:revision} )
      this.items.splice(this.editedIndex, 1)
      this.closeDelete()
      this.revisionDeleteDialog = false
    }


  },
}
</script>