<template>
  <v-row justify="center">
    <v-dialog v-model="show" fullscreen hide-overlay :retain-focus="false" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.stop="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Settings</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn dark text @click="createRevision" v-bind:disabled="isDisabled">
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div class="basic-config-wrapper">
          <BasicConfig @isDisabled="disable" :editItem="editItem" :templates="templates"/>
        </div>
      </v-card>
    </v-dialog>
    <app-revision-dialog :dialog="revisionDialog" @save="saveWithRevision"></app-revision-dialog>
  </v-row>
</template>

<script>
import BasicConfig from "./institutConfig/BasicConfig"
import AppRevisionDialog from "./Revision/RevisionDialog.vue";

export default {
  data() {
    return {
      tab: 0,
      isDisabled: true,
      revisionDialog: false
    }
  },
  props: {
    value: Boolean,
    editItem: null,
    templates: null,
  },
  computed: {
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  components: {
    AppRevisionDialog,
    BasicConfig,
  },
  methods: {
    createRevision() {
      this.revisionDialog = true
    },
    disable(value) {
      this.isDisabled = value
    },
    saveWithRevision(revision){
      this.revisionDialog = false
      this.$store.dispatch("addinstitut", {institut:this.editItem,revision:revision})
    }
  }
}
</script>

<style scoped>

.basic-config-wrapper {
  margin-top: 50px;
  max-width: 930px;
  margin-right: auto;
  margin-left: auto;
}
</style>

<style>
.v-tabs-slider-wrapper {
  color: red;
  height: 4px !important;
}
</style>