<template>
    <v-container>
        <v-card class="institute-basic-wrapper">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            Bezeichnung
                        </th>
                        <th class="text-left">
                            Wert
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Logo</td>
                        <td>
                            <v-file-input v-model="setEmpty" clearble show-size ref="inputFile" counter single
                                          @click:clear="deleteNewLogo"
                                          @change="handleImage" accept="image/*" label="Logo hochladen">
                            </v-file-input>
                        </td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td>
                            <v-text-field v-model="editItem.name" label="Name"
                                          placeholder="Name eingeben..."></v-text-field>
                        </td>
                    </tr>
                    <tr v-if="templates.length > 0">
                        <td>Template</td>
                        <td>
                            <v-combobox v-model="editItem.template" item-text="name" :items="templates" label="Template"
                                        placeholder="Template auswählen..."></v-combobox>
                        </td>
                    </tr>
                    <tr>
                        <td>Region</td>
                        <td>
                            <v-text-field v-model="editItem.region" label="Region"
                                          placeholder="Region eingeben..."></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Instituts-Nr.</td>
                        <td>
                            <v-text-field v-model="editItem.institute_nr" label="Instituts-Nr."
                                          placeholder="Instituts-Nr. eingeben...">
                            </v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>VRZ</td>
                        <td>
                            <v-text-field v-model="editItem.vrz" label="VRZ"
                                          placeholder="VRZ eingeben..."></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Instituts Domain</td>
                        <td>
                            <v-text-field v-model="editItem.instituteAccessDomain" label="Instituts Domain"
                                          placeholder="Instituts Domain eingeben..."></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>MFP Auth Token</td>
                        <td>
                            <v-text-field v-model="editItem.instituteToken" label="MFP Auth Token"
                                          placeholder="MFP Auth Token eingeben..."></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Update Gruppe</td>
                        <td>
                            <v-text-field v-model="editItem.updateGroup" label="Update Gruppe"
                                          placeholder="Update Gruppe eingeben..."></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Status</td>
                        <td>
                            <v-combobox v-model="editItem.status" :items="status" label="Status"
                                        placeholder="Template auswählen..."></v-combobox>
                        </td>
                    </tr>
                    <tr>
                        <td>Ist Template</td>
                        <td>
                            <v-checkbox v-model="editItem.isTemplate"></v-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>Ablaufdatum erfassen</td>
                        <td>
                            <v-combobox v-model="editItem.captureValidUntil" :items="captureValidUntil"
                                        label="Ablaufdatum optional"
                                        placeholder="Option auswählen..."></v-combobox>
                        </td>
                    </tr>
                    <tr>
                        <td>Senior/Junior ignorieren</td>
                        <td>
                            <v-checkbox v-model="editItem.ignoreNameSuffix"></v-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>Title Case für Scans (Erster Buchstabe groß rest klein)</td>
                        <td>
                            <v-checkbox v-model="editItem.scanDataTitleCase"></v-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>Geburtsname erfassen</td>
                        <td>
                            <v-combobox v-model="editItem.captureBirthName" :items="captureBirthName"
                                        label="Geburtsname prüfen"
                                        placeholder="Option auswählen..."></v-combobox>
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            status: [
                "DISABLED", "ACTIVE"
            ],
            captureValidUntil: [
                "ACTIVE", "OPTIONAL", "DISABLED"
            ],
            captureAddressData: [
                "UPDATE", "COMPARE", "DISABLED"
            ],
            captureBirthName: [
                "COMPARE_UPDATE", "COMPARE", "DISABLED"
            ]
        }
    },
    created() {
        this.$emit("newLogo", false)
    },
    props: {
        editItem: {},
        templates: null,
        emptyInput: null
    },
    computed: {
        isDisabled() {
            var disabled = true
            if (this.editItem.logo && this.editItem.status && this.editItem.name != "" && this.editItem.region != "" && this.editItem
                .institute_nr != "" && this.editItem.vrz != "" && this.editItem.instituteToken != "" && this.editItem
                .updateGroup != "") {
                disabled = false
            }
            return disabled
        },
        setEmpty() {
            return this.emptyInput
        }
    },
    watch: {
        isDisabled(disabled) {
            this.$emit("isDisabled", disabled)
        },
    },
    methods: {
        deleteNewLogo() {
            this.$emit("newLogo", false)
        },
        handleImage(e) {
            if (e) {
                this.$emit("newLogo", true)
                var reader = new FileReader();
                reader.readAsDataURL(e);
                var that = this
                reader.onload = function () {
                    that.editItem.logo = reader.result
                };
            } else {
                this.$emit("newLogo", false)
            }


        },
    }
}
</script>

<style scoped>
.institute-basic-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 15px;
}

.institute-basic-wrapper:hover {
    box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
}
</style>