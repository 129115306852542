<template>
  <v-container>
    <v-card class="institute-basic-wrapper">
      <v-simple-table>
        <template v-slot:default>
          <thead>
          <tr>
            <th class="text-left">
              Bezeichnung
            </th>
            <th class="text-left">
              Wert
            </th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>Enabled Auto Email</td>
            <td>
              <v-checkbox v-model="reporting.mailEnabled"></v-checkbox>
            </td>
          </tr>
          <tr>
            <td>Email Benutzer</td>
            <td>
              <v-combobox
                  :disabled="!reporting.mailEnabled"
                  v-model="reporting.reportingEmails"
                  :items="emails"
                  :search-input.sync="search"
                  hide-selected
                  hint="Durch enter hinzufügen"
                  label="E-Mail Empfänger hinzufügen."
                  multiple
                  persistent-hint
                  small-chips
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>
                        Email Addresse "<strong>{{ search }}</strong>" hinzufügen.
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </template>
              </v-combobox>
            </td>
          </tr>
          <tr>
            <td>Mail Subject</td>
            <td>
              <v-text-field :disabled="!reporting.mailEnabled" v-model="reporting.mailSubject"  label="Subject">
              </v-text-field>
            </td>
          </tr>
          <tr>
            <td>Email Body Template</td>
            <v-container fluid>
              <v-textarea
                  label="Email Template"
                  :disabled="!reporting.mailEnabled"
                  v-model="reporting.mailTemplateHtml"
              ></v-textarea>
            </v-container>
          </tr>
          <tr>
            <td>PDF Template</td>
            <v-container fluid>
              <v-textarea
                  label="PDF Template"
                  v-model="reporting.pdfTemplateHtml"
              ></v-textarea>
            </v-container>
          </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card>
  </v-container>
</template>

<script>

export default {
  props: {
    reporting: {},
  },
  created() {
  },
  data(){
    return{
      emails:[],
      search:""
    }
  },

  computed: {},

  methods: {},
}
</script>

<style scoped>
.institute-basic-wrapper {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
  padding: 15px;
  margin-bottom: 40px;

}

.institute-basic-wrapper:hover {
  box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
}
</style>