<template>
  <v-dialog v-model="show" fullscreen hide-overlay :retain-focus="false" transition="dialog-bottom-transition">
    <v-card style="background-color:#f0f0f0">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click.stop="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Scans</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>

        </v-toolbar-items>
      </v-toolbar>
      <v-container fluid class="row-outer-container">
        <v-data-iterator :items="filteredItems" :items-per-page.sync="itemsPerPage" :page.sync="page"
          hide-default-footer>
          <template v-slot:header>
            <v-toolbar dark class="row-toolbar">
              <v-text-field v-model="search" clearable solo-inverted hide-details prepend-inner-icon="mdi-magnify"
                label="Search" id="row-search-field"></v-text-field>
              <template v-if="$vuetify.breakpoint.mdAndUp">
                <v-spacer></v-spacer>
                <v-select v-model="onlyShow" flat solo-inverted hide-details :items="status" item-text="displayText"
                  prepend-inner-icon="mdi-filter-menu-outline" label="Sort by"></v-select>
                <v-spacer></v-spacer>
                <v-btn-toggle id="row-button-toggle" v-model="sortDesc" mandatory>
                  <v-btn large depressed :value="false" @click.stop="loadImages()">
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </v-btn-toggle>
              </template>
            </v-toolbar>
          </template>

          <template v-slot:default="props">
            <ActionDialog v-model=actionDialog :scan="editScan" :countries="countries" :isCompany="isCompany"
              :docs="docTypes" />
            <PickDocument v-model=pickDocDialog :scan="editScan" :docs="docTypes" />
            <v-row>
              <v-col v-for="(item,i) in props.items" :index="i" :key="item.name" cols="12" sm="12" md="12" lg="12">
                <v-card @click.stop="triggerActionDialog(item)" class="row-card-wrapper">
                  <div style="display:flex;width: fit-content;position: absolute;right: 0px;top: 0px;padding:5px">
                    <v-icon @click.stop="downloadOriginalData(item)" style="color: red;font-size: 30px;">mdi-paperclip
                    </v-icon>
                    <div v-if="item.status.toLowerCase() == 'bearbeitet'">
                      <v-icon @click.stop="downloadPdf(item)" style="color: red;font-size: 30px;">mdi-file-pdf-outline
                      </v-icon>
                    </div>
                  </div>
                  <div v-if="item.status.toLowerCase() != 'gelöscht' && false"
                    @click.stop="triggerDeleteDialog(item.id)"
                    style="width: fit-content;position: absolute;right: 0px;top: 0px;padding:5px">
                    <v-icon style="color: red;font-size: 30px;">mdi-delete</v-icon>
                  </div>
                  <v-divider></v-divider>

                  <div class="cardview-row-wrapper">
                    <div class="image-row-wrapper">
                      <v-img v-if="item.images[0]" v-bind:lazy-src=item.images[0].processedImage
                        v-bind:src=item.images[0].processedImage>
                      </v-img>
                    </div>
                    <div class="text-row-wrapper">
                      <v-card-title class="subheading font-weight-bold">
                        {{ epochToDate(item.createdAt) }}
                      </v-card-title>
                      <div class="text-row-wrapper">
                        <v-list dense>
                          <v-list-item>
                            <v-list-item-content class="v-list-item__content_description">
                              Status
                            </v-list-item-content>
                            <v-list-item-content class="v-list-item__content_value">
                              {{ (item.status).toLowerCase() }}
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content class="v-list-item__content_description">
                              Art der Legitimation
                            </v-list-item-content>
                            <v-list-item-content class="v-list-item__content_value">
                              {{ docType(item) }}

                              <!--<div v-if="docType(item)" @click.stop="triggerPickDocDialog(item)"
                                style="margin-left:5px; flex:0">
                                <v-icon style="display:flex">mdi-pencil</v-icon>
                              </div> -->
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content class="v-list-item__content_description">
                              Name
                            </v-list-item-content>
                            <v-list-item-content class="v-list-item__content_value">
                              <div v-if="item.scanData.lastName">
                                {{ item.scanData.lastName.fieldValue }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content class="v-list-item__content_description">
                              Dokumentennummer
                            </v-list-item-content>
                            <v-list-item-content class="v-list-item__content_value">
                              <div v-if="item.scanData.documentNumber">
                                {{ item.scanData.documentNumber.fieldValue }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content class="v-list-item__content_description">
                              Bearbeiter
                            </v-list-item-content>
                            <v-list-item-content class="v-list-item__content_value">
                              <div v-if="item.user">
                                {{ item.user }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-content class="v-list-item__content_description">
                              Sicherheitstext
                            </v-list-item-content>
                            <v-list-item-content class="v-list-item__content_value">
                              <div v-if="item.security">
                                {{ generateSecurityText(item.security.securityStatus) }}
                              </div>
                            </v-list-item-content>
                          </v-list-item>
                        </v-list>
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </template>

          <template v-slot:footer>
            <v-row class="mt-2" align="center" justify="center">
              <div class="row-footer-spacer">
                <span class="grey--text">Items per page</span>
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn dark text class="ml-2 row-items-per-page" v-bind="attrs" v-on="on">
                      {{ itemsPerPage }}
                      <v-icon>mdi-chevron-down</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item v-for="(number, index) in itemsPerPageArray" :key="index"
                      @click="updateItemsPerPage(number)">
                      <v-list-item-title>{{ number }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
              <div class="text-center row-footer-spacer">
                <v-pagination v-model="page" :length=numberOfPages prev-icon="mdi-menu-left" next-icon="mdi-menu-right">
                </v-pagination>
              </div>
              <div class="row-footer-spacer"></div>
            </v-row>
          </template>
        </v-data-iterator>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
  import ActionDialog from './Dialog'
  import PickDocument from './PickDocument'

  export default {
    props: {
      scans: null,
      value: null,
      editItem: null
    },
    data() {
      return {
        deleteDialog: false,
        items: null,
        scanToDeleteID: null,
        actionDialog: false,
        companyDialog: false,
        pickDocDialog: false,
        editScan: "",
        search: "",
        isCompany: false,
        showDialog: false,
        itemsPerPageArray: [4, 8, 12],
        filter: {},
        sortDesc: true,
        page: 1,
        itemsPerPage: 4,
        sortBy: 'createdAt',
        onlyShow: "Neu",
        status: [{
            displayText: "Neu",
            technical: "Neu"
          },
          {
            displayText: "Bearbeitet",
            technical: "bearbeitet"
          },
          {
            displayText: "Gelöscht",
            technical: "geloescht"
          },
          {
            displayText: "Alle",
            technical: "alle"
          },
        ],
        keys: [
          'Name',
          'Dokumentenart',
          'Scanndatum',
          'Abteilung',
        ],
      }
    },
    components: {
      ActionDialog,
      PickDocument,
    },
    computed: {
      securityStatusTexts() {
        return this.$store.getters.getSecurityStatusTexts
      },
      countries() {
        return this.$store.getters.getCountries
      },
      docTypes() {
        var docs = []
        this.editItem.svzLeg.forEach(element => {
          if (element.active && element.visibleInOSP) {
            docs.push(element)
          }
        });
        docs.sort(function (a, b) {
          return parseInt(a.position) - parseInt(b.position);
        });
        return docs
      },
      numberOfPages() {
        return Math.ceil(this.filteredItems.length / this.itemsPerPage)
      },
      filteredKeys() {
        return this.keys.filter(key => key !== 'Name')
      },
      filteredItems() {
        var onlyShow = this.onlyShow
        var search = this.search
        var showItems = []
        this.scans.forEach(element => {
          if (onlyShow.toLowerCase() == element.status.toLowerCase() || onlyShow.toLowerCase() == "alle") {
            showItems.push(element)
          }
        });
        var finalItems = showItems
        if (search != "") {
          finalItems = []
          showItems.forEach(element => {
            if (!search || (element.scanData.dateOfBirth.fieldValue && element.scanData.dateOfBirth.fieldValue
                .toString().toLowerCase().includes(search.toString().toLowerCase())) || (element.scanData.documentNumber.fieldValue && element.scanData.documentNumber
                .fieldValue.toString().toLowerCase().includes(search.toString().toLowerCase())) || (element.user && element.user.toString().toLowerCase().includes(search
                .toString().toLowerCase())) || element.scanData.firstNames.fieldValue.toString().toLowerCase().includes(search
                .toString().toLowerCase()) || element.scanData.lastName.fieldValue.toString().toLowerCase()
              .includes(search.toString().toLowerCase()) || this.nationality(element.scanData.nationality
                .fieldValue).toString().toLowerCase().includes(search.toString().toLowerCase()) || this.docType(
                element).toString().toLowerCase().includes(search.toString().toLowerCase())) {
              finalItems.push(element)
            }
          });
        }
        return finalItems
      },
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
    },
    watch: {
      page() {
        this.$store.dispatch("loadVisibleImages", {
          itemsPerPage: this.itemsPerPage,
          page: this.page,
          filteredItems: this.filteredItems
        });
      },
      itemsPerPage() {
        this.$store.dispatch("loadVisibleImages", {
          itemsPerPage: this.itemsPerPage,
          page: this.page,
          filteredItems: this.filteredItems
        });
      },
      filteredItems() {
        this.$store.dispatch("loadVisibleImages", {
          itemsPerPage: this.itemsPerPage,
          page: this.page,
          filteredItems: this.filteredItems
        });
      }
    },
    created() {
      this.$store.dispatch("loadCountries");
    },
    methods: {
      generateSecurityText(key) {
        if (!this.securityStatusTexts[key]) {
          return ""
        } else {
          return this.securityStatusTexts[key].text
        }
      },
      downloadPdf(scan) {
        this.$store.dispatch("downloadPdf", scan)
      },
      downloadOriginalData(scan) {
        this.$store.dispatch("downloadOriginalData", scan)
      },
      customSearch(items, search, filter) {
        search = search.toString().toLowerCase()
        return items.filter(i => (
          Object.keys(i).some(j => filter(i[j], search))
        ))
      },
      deleteScan() {
        this.$store.dispatch("deleteScan", this.scanToDeleteID);
        this.deleteDialog = false
      },
      epochToDate(epoch) {
        return new Date(epoch).toLocaleString().replace(",", "");
      },
      nationality(item) {
        var found = this.$store.getters.getCountries.find(x => x.icao3 == item)
        return (found ? found.nationalitaet : '');
      },
      docType(item) {
        var found = this.docTypes.find(x => x.ospDocType == item.dtype.fieldValue)
        return (found ? found.shortDesc : '');
      },
      triggerDeleteDialog(id) {
        this.scanToDeleteID = id
        this.deleteDialog = true
      },
      triggerPickDocDialog(scan) {
        this.editScan = scan
        this.pickDocDialog = true
      },
      triggerActionDialog(scan) {
        this.editScan = scan
        if (scan.security.securityStatus == "EXPIRED") {
          this.$store.dispatch("createError", {
            status: "EXPIRED",
            data: "Das gewählte Dokument ist abgelaufen und kann nicht zur Legitimation verwendet werden.",
            scanID: this.editScan.id
          });
          return
        }
        if (this.editScan.scanData.dtype.fieldValue && this.editScan.persontype) {
          if (scan.persontype == "JP") {
            this.isCompany = true
            this.actionDialog = true
          } else {
            this.isCompany = false
            this.actionDialog = true
          }
        } else {

          this.pickDocDialog = true
        }
      },
      nextPage() {
        if (this.page + 1 <= this.numberOfPages) this.page += 1
      },
      formerPage() {
        if (this.page - 1 >= 1) this.page -= 1
      },
      updateItemsPerPage(number) {
        this.itemsPerPage = number
      },
      checkValues(element, search) {
        for (var key in element) {
          if (typeof element[key] === "object") {
            this.checkValues(element[key], search)
          } else {
            if (element[key].toString().toLowerCase().includes(search.toString().toLowerCase())) {
              return true
            }
          }
        }
        return false
      }
    },
  }
</script>
<style>
  .row {
    margin: 0px;
  }

  .row-outer-container {
    background-color: #f0f0f0;
    max-width: 930px;
  }

  .row-toolbar {
    margin-bottom: 20px !important;
    background-color: #666 !important;
  }

  #row-button-toggle {
    background-color: white;
  }

  #row-button-descending,
  #row-button-ascending {
    background-color: white;
  }

  #row-button-descending span i,
  #row-button-ascending span i {
    color: red;
  }

  .cardview-row-wrapper {
    display: flex;
    justify-content: space-between
  }

  .row-card-wrapper:hover {
    box-shadow: 0 8px 16px 0 rgba(255, 0, 0, 0.35) !important;
    transform: scale(1.01);
  }

  .image-row-wrapper {
    padding: 30px;
    width: 50%;
    display: flex;
    justify-content: center
  }

  .image-row-wrapper div {
    margin-top: auto;
    margin-bottom: auto;
  }

  .text-row-wrapper {
    padding: 10px;
    width: 100%;
    display: block;
    display: block;
    text-align: center;
  }

  .texticon-row-wrapper {
    padding-left: 16px;
    display: flex;
    justify-content: left;
  }

  .row-footer-spacer {
    width: 33%;
  }

  .row-items-per-page span {
    color: red;
  }

  .v-list-item__content_value {
    text-align: left;
  }
</style>