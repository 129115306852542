<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-card class="institute-control-document-wrapper">
                    <v-card-title>Dokumentenart/Nationalität</v-card-title>
                    <v-divider></v-divider>
                    <v-data-table :headers="docHeaders" :items="controlChecks.dtypeNationChecks" :search="docsSearch"
                        class="institute-control-table">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-text-field v-model="docsSearch" append-icon="mdi-magnify" label="Suchen" single-line
                                    hide-details></v-text-field>

                                <v-spacer></v-spacer>
                                <v-btn color="primary" class="mr-2" @click="createDocItem">
                                    <v-icon>mdi-text-box-plus-outline</v-icon>
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editDocItem(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteDocItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:item.logo="{ item }">
                            <img v-bind:src="item.logo" style="width: auto; height: 100px" />
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-card class="institute-control-fields-wrapper">
                    <v-card-title>Feldänderungen</v-card-title>
                    <v-divider></v-divider>
                    <v-data-table :headers="fieldsHeaders" :items="controlChecks.fieldChangedChecks"
                        :search="fieldsSearch" sort-by="calories" class="institute-control-table">
                        <template v-slot:top>
                            <v-toolbar flat>
                                <v-text-field v-model="fieldsSearch" append-icon="mdi-magnify" label="Suchen"
                                    single-line hide-details></v-text-field>

                                <v-spacer></v-spacer>
                                <v-btn color="primary" class="mr-2" @click="createFieldsItem">
                                    <v-icon>mdi-beaker-plus-outline</v-icon>
                                </v-btn>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <v-icon small class="mr-2" @click="editFieldsItem(item)">
                                mdi-pencil
                            </v-icon>
                            <v-icon small @click="deleteFieldsItem(item)">
                                mdi-delete
                            </v-icon>
                        </template>
                        <template v-slot:item.logo="{ item }">
                            <img v-bind:src="item.logo" style="width: auto; height: 100px" />
                        </template>
                    </v-data-table>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-card class="institute-control-fields-wrapper">
                    <v-card-title>Auto Legitimierung</v-card-title>
                    <v-divider></v-divider>
                    <v-simple-table>
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th class="text-left">
                                        Bezeichnung
                                    </th>
                                    <th class="text-left">
                                        Wert
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>Autolegitimierung Vollkontrolle</td>
                                    <td>
                                        <v-checkbox v-model="controlChecks.autoLegitimizeCheck"></v-checkbox>
                                    </td>
                                </tr>
                            </tbody>
                        </template></v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="deleteDialog" max-width="600px">
            <v-card>
                <v-card-title class="headline">Sind sie sich sicher dass sie diesen Eintrag löschen wollen?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="deleteItemConfirm">Löschen</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editDocDialog" max-width="600px">
            <v-card>
                <v-card-title class="headline">Dokument bearbeiten</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-combobox v-model="newDoc.nation" :items="countries" item-value="icao3"
                                    item-text="nationalitaet" :return-object="false" hide-selected label="Nationalität">
                                </v-combobox>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-combobox v-model="newDoc.dtype" :items="svzLeg" item-value="ospDocType"
                                    item-text="shortName" :return-object="false" hide-selected label="Dokumentenart">
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="editDocDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="saveEditedDocItem">Speichern</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createDocDialog" max-width="600px">
            <v-card>
                <v-card-title class="headline">Dokument erstellen</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-combobox v-model="newDoc.nation" :items="countries" item-value="icao3"
                                    item-text="vollform" :return-object="false" hide-selected label="Nationalität">
                                </v-combobox>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">

                                <v-combobox v-model="newDoc.dtype" :items="svzLeg" item-value="ospDocType"
                                    item-text="shortName" :return-object="false" hide-selected label="Dokumentenart">
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="createDocDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="saveNewDocItem">Speichern</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editFieldsDialog" max-width="600px">
            <v-card>
                <v-card-title class="headline">Dokument bearbeiten</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="newFields.fieldname" label="Feldname"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-text-field v-model="newFields.personType" label="Personenart"></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="editFieldsDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="saveEditedFieldsItem">Speichern</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createFieldsDialog" max-width="600px">
            <v-card>
                <v-card-title class="headline">Dokument erstellen</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-combobox v-model="newFields.fieldname" :items="fieldOptions" label="Feld"
                                    placeholder="Feld auswählen..."></v-combobox>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-combobox v-model="newFields.personType" :items="personOptions" label="Personenart"
                                    placeholder="Personenart auswählen..."></v-combobox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="createFieldsDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="saveNewFieldsItem">Speichern</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    export default {
        data() {
            return {
                docsSearch: "",
                fieldsSearch: "",
                createDocDialog: false,
                editDocDialog: false,
                createFieldsDialog: false,
                editFieldsDialog: false,
                deleteDialog: false,
                tableNr: 0,
                editedIndex: -1,
                fieldOptions: [
                    "firstNames", "lastName", "dateOfBirth", "placeOfBirth", "documentNumber", "issuer",
                    "issuerCountry",
                    "issuedAt", "validUntil", "nationality", "dtype","birthName"
                ],
                personOptions: [
                    "NP", "JP"
                ],
                docHeaders: [{
                    text: 'Nationalität',
                    align: 'start',
                    value: 'nation',
                }, {
                    text: 'Dokumentenart',
                    align: 'start',
                    value: 'dtype',
                }, {
                    text: '',
                    align: 'end',
                    value: 'actions',
                    sortable: false
                }, ],
                fieldsHeaders: [{
                        text: 'Feldname',
                        align: 'start',
                        sortable: false,
                        value: 'fieldname',
                    },
                    {
                        text: 'Personenart',
                        align: 'start',
                        sortable: false,
                        value: 'personType',
                    },
                    {
                        text: '',
                        align: 'end',
                        value: 'actions',
                        sortable: false
                    },
                ],
                emptyDoc: {
                    nation: "",
                    dtype: "",
                },
                newDoc: {
                    nation: "",
                    dtype: "",
                },
                emptyFields: {
                    fieldname: "",
                    personType: ""
                },
                newFields: {
                    fieldname: "",
                    personType: ""
                },
            }
        },
        watch: {
            createFieldsDialog(visible) {
                if (!visible) {
                    this.newFields = JSON.parse(JSON.stringify(this.emptyFields))
                }
            },
            editFieldsDialog(visible) {
                if (!visible) {
                    this.newFields = JSON.parse(JSON.stringify(this.emptyFields))
                }
            },
            createDocDialog(visible) {
                if (!visible) {
                    this.newDoc = JSON.parse(JSON.stringify(this.emptyDoc))
                }
            },
            editDocDialog(visible) {
                if (!visible) {
                    this.newDoc = JSON.parse(JSON.stringify(this.emptyDoc))
                }
            },
        },
        created() {},
        props: {
            controlChecks: null,
            countries: null,
            svzLeg: null,
        },
        methods: {
            createDocItem() {
                this.createDocDialog = true
            },
            editDocItem(item) {
                this.editedIndex = this.controlChecks.dtypeNationChecks.indexOf(item)
                this.newDoc = item
                this.editDocDialog = true
            },
            saveNewDocItem() {
                this.controlChecks.dtypeNationChecks.push(this.newDoc)
                this.editDocDialog = false
                this.createDocDialog = false
            },
            saveEditedDocItem() {
                this.controlChecks.dtypeNationChecks[this.editedIndex] = this.newDoc
                this.editDocDialog = false
                this.createDocDialog = false
            },
            deleteDocItem(item) {
                this.editedIndex = this.controlChecks.dtypeNationChecks.indexOf(item)
                this.tableNr = 0
                this.deleteDialog = true
            },
            createFieldsItem() {
                this.createFieldsDialog = true
            },
            editFieldsItem(item) {
                this.editedIndex = this.controlChecks.fieldChangedChecks.indexOf(item)
                this.newFields = item
                this.editFieldsDialog = true

            },
            saveNewFieldsItem() {
                this.controlChecks.fieldChangedChecks.push(this.newFields)
                this.editFieldsDialog = false
                this.createFieldsDialog = false
            },
            saveEditedFieldsItem() {
                this.controlChecks.fieldChangedChecks[this.editedIndex] = this.newFields
                this.editFieldsDialog = false
                this.createFieldsDialog = false
            },
            deleteFieldsItem(item) {
                this.editedIndex = this.controlChecks.fieldChangedChecks.indexOf(item)
                this.tableNr = 1
                this.deleteDialog = true

            },
            deleteItemConfirm() {
                switch (this.tableNr) {
                    case 0:
                        this.controlChecks.dtypeNationChecks.splice(this.editedIndex, 1)
                        break;
                    case 1:
                        this.controlChecks.fieldChangedChecks.splice(this.editedIndex, 1)
                        break;
                    default:
                        break;
                }
                this.deleteDialog = false
            }
        }
    }
</script>

<style scoped>
    .institute-control-document-wrapper {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
        padding: 15px;
        height: 100%;
    }

    .institute-control-fields-wrapper {
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
        padding: 15px;
        height: 100%;
    }

    .institute-control-fields-wrapper:hover,
    .institute-control-document-wrapper:hover {
        box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
    }

    .institute-control-table {
        margin-top: 20px;
    }
</style>