<template>
  <v-data-table :headers="headers" :search="search" :items="dtypeMapping" sort-by="calories" class="elevation-1">
    <template v-slot:top>
      <v-toolbar flat>
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>
                mdi-text-box-plus-outline
              </v-icon>

            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.docType" label="Dokumentenart"></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-combobox v-model="editedItem.issuerCountry" :items="countries" item-value="icao3"
                                    item-text="kurzform" :return-object="false" hide-selected label="Ausstellerland">
                                </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-combobox v-model="editedItem.ospDocType" :items="svzLeg" item-value="ospDocType"
                                    item-text="shortName" :return-object="false" hide-selected label="OSP Dokumentenart">
                                </v-combobox>
                  </v-col>
                  <v-col cols="12" sm="12" md="6">
                    <v-text-field v-model="editedItem.description" label="Beschreibung"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="close">
                Abbrechen
              </v-btn>
              <v-btn color="primary" text @click="save">
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline">Wollen sie diesen Eintrag wirklich löschen?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDelete">Abbrechen</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">Löschen</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    props: {
      dtypeMapping: null,
      countries: null,
            svzLeg: null,
    },
    data() {
      return {
        search: '',
        dialog: false,
        dialogDelete: false,
        headers: [{
            text: 'Dokumenttyp',
            value: 'docType'
          },
          {
            text: 'Ausstellerland',
            value: 'issuerCountry'
          },
          {
            text: 'OSP Dokumenttyp',
            value: 'ospDocType'
          },
          {
            text: 'Beschreibung',
            value: 'description',
          },
          {
            text: '',
            align: 'end',
            value: 'actions',
            sortable: false
          },
        ],
        editedIndex: -1,
        editedItem: {
          docType: '',
          issuerCountry: "",
          ospDocType: "",
          description: "",
        },
        defaultItem: {
          docType: '',
          issuerCountry: "",
          ospDocType: "",
          description: "",
        },
      }
    },
    computed: {
      formTitle() {
        return this.editedIndex === -1 ? 'Neuen Eintrag erstellen' : 'Eintrag bearbeiten'
      },
      formCompareIssuer(text){
        return text ? "Ja" : "Nein"
      }
    },

    watch: {
      dialog(val) {
        val || this.close()
      },
      dialogDelete(val) {
        val || this.closeDelete()
      },
    },

    methods: {

      editItem(item) {
        this.editedIndex = this.dtypeMapping.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem(item) {
        this.editedIndex = this.dtypeMapping.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm() {
        this.dtypeMapping.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close() {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete() {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save() {
        if (this.editedIndex > -1) {
          Object.assign(this.dtypeMapping[this.editedIndex], this.editedItem)
        } else {
          this.dtypeMapping.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>