<template>
  <v-data-table
    :headers="headers"
    :items="acceptanceCriteria"
    :search="search"
    sort-by="nationality"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar
        flat
      >
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line
                                    hide-details></v-text-field>
        <v-spacer></v-spacer>
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="primary"
              dark
              class="mb-2"
              v-bind="attrs"
              v-on="on"
            >
              Neuer Eintrag
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                  <v-combobox v-model="editedItem.nationality" :items="countries" item-value="icao3"
  item-text="nationalitaet" :return-object="false" hide-selected label="Nationalität"></v-combobox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                  <v-combobox v-model="editedItem.country" :items="countries" item-value="icao3"
  item-text="vollform" :return-object="false" hide-selected label="Land"></v-combobox>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="12"
                    md="6"
                  >
                    <v-combobox v-model="editedItem.dtype" :items="svzLeg" item-value="ospDocType"
                                    item-text="shortName" :return-object="false" hide-selected label="Dokumentenart">
                                </v-combobox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="close"
              >
                Abbrechen
              </v-btn>
              <v-btn
                color="primary"
                text
                @click="save"
              >
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title class="headline">Wollen Sie diesen Eintrag wirklich löschen?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="closeDelete">Abbrechen</v-btn>
              <v-btn color="primary" text @click="deleteItemConfirm">Löschen</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
  </v-data-table>
</template>

<script>
  export default {
    data: () => ({
      dialog: false,
      dialogDelete: false,
      search:"",
      headers: [
        {
          text: 'Nationalität',
          align: 'start',
          value: 'nationality',
        },
        { text: 'Land', value: 'country' },
        { text: 'Dokumentenart', value: 'dtype' },
        { text: '', value: 'actions', sortable: false },
      ],
      editedIndex: -1,
      editedItem: {
        nationality: '',
        country: "",
        dtype: "",
        hint: "",
      },
      defaultItem: {
        nationality: '',
        country: "",
        dtype: "",
        hint: "",
      },
    }),

    props: {
            acceptanceCriteria: null,
            countries: null,
            svzLeg: null,
        },

    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Neuen Eintrag erstellen' : 'Eintrag bearbeiten'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },

    methods: {

      editItem (item) {
        this.editedIndex = this.acceptanceCriteria.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.acceptanceCriteria.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.acceptanceCriteria.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      save () {
        if (this.editedIndex > -1) {
          Object.assign(this.acceptanceCriteria[this.editedIndex], this.editedItem)
        } else {
          this.acceptanceCriteria.push(this.editedItem)
        }
        this.close()
      },
    },
  }
</script>