<template>
    <div class="text-center">
        <v-dialog v-model="show" width="500">
            <v-card>
                <v-card-title class="primary pickDoc-title-wrapper">
                    Dokumentenart auswählen
                </v-card-title>

                <v-divider></v-divider>
                <v-simple-table>
                    <template v-slot:default>
                        <tbody>
                            <tr>
                                <td>Dokumentenart</td>
                                <td>
                                    <v-autocomplete v-model="selectedDoc" :items="docs"
                                        item-text="shortName" :return-object="false" item-value="ospDocType" hide-selected
                                        label="Dokumentenart">
                                    </v-autocomplete>
                                </td>
                            </tr>
                            <!--                           <tr v-if="selectedDoc.personType != 'NP'">
                                <td>Personenart</td>
                                <td>
                                    <v-combobox v-model="selectedDet" :items="documents" item-value="ospDocType"
  item-text="personType" :return-object="false" hide-selected label="Personenart">
                                    </v-combobox>
                                </td>
                            </tr> -->
                        </tbody>
                    </template>
                </v-simple-table>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="show = false">
                        Abbrechen
                    </v-btn>
                    <v-btn color="primary" text @click="save">
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                selectedDoc: {},
            }
        },
        props: {
            value: Boolean,
            scan: null,
            docs: null
        },
        computed: {
            show: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }
            },
        },
        watch: {
            show(visible) {
                if (visible) {
                    this.selectedDoc = JSON.parse(JSON.stringify(this.scan.dtype.fieldValue))
                }
            }
        },
        methods: {
            save() {
                this.$store.dispatch("setDType", {scan: this.scan, dtype: this.selectedDoc})
                this.show = false
            }
        }
    }
</script>

<style scoped>
    .pickDoc-title-wrapper {
        color: #fff
    }
</style>