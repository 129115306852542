<template>
  <v-row justify="center">
    <v-dialog v-model="show" fullscreen hide-overlay :retain-focus="false" transition="dialog-bottom-transition">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.stop="onClose">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Report</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>

          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-row>
            <v-col cols="12" sm="6">
              <v-list dense>
                <v-list-item>
                  <app-date-time-picker :value="dateTime" @change="changeDate"></app-date-time-picker>
                </v-list-item>
                <v-list-item>
                  <v-btn color="primary" @click="send">
                    Anfragen
                  </v-btn>
                  <v-spacer>

                  </v-spacer>
                </v-list-item>
                <v-list-item>
                  <v-btn color="primary" @click="getFullReport">
                    PDF-Report
                  </v-btn>
                </v-list-item>
              </v-list>
            </v-col>
          </v-row>
        </v-container>
        <v-divider></v-divider>
        <v-container>
          <v-list dense>
            <v-list-item>
              <v-list-item-content>
                Vom Scanner (MFP) an LS|n übertragene Legitimationen:
              </v-list-item-content>
              <v-list-item-content>
                {{ summary ? summary.createdLegitimations : '' }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                An OSPlus übertragene (bearbeitete) Legitimationen:
              </v-list-item-content>
              <v-list-item-content>
                {{ summary ? summary.completedLegitimations : '' }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                Vom Anwender gelöschte Legitimationen:
              </v-list-item-content>
              <v-list-item-content>
                {{ summary ? summary.deletedByUser : '' }}
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-content>
                Automatisch gelöschte (unbearbeitete) Legitimationen:
              </v-list-item-content>
              <v-list-item-content>
                {{ summary ? summary.deletedAfterInactivity : '' }}
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-container>
        <v-divider></v-divider>
        <template>
          <v-data-table :headers="headers" sort-by="createdAt" sort-desc :items="reports" class="elevation-1">
            <template v-slot:item.createdAt="{ item }">
              {{ timestampToDate(item.createdAt) }}
            </template>
          </v-data-table>
        </template>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import appDateTimePicker from "./DateRangePicker.vue";

export default {
  components: {appDateTimePicker},
  props: {
    value: Boolean,
    editItem: null,
  },
  data: () => ({
    menu: false,
    requestParams: {
      "from": "",
      "to": "",
    },
    headers: [{
      text: 'Datum',
      value: 'createdAt'
    },
      {
        text: 'Beschreibung',
        value: 'action',
        sortable: false,
      }, {
        text: 'Benutzer',
        value: 'user',
        sortable: false,
      }, {
        text: 'Scan',
        value: 'scanID',
        sortable: false,
      }
    ],
  }),
  computed: {
    dateTime() {
      return [this.requestParams.from, this.requestParams.to]
    },
    reports() {
      return this.$store.getters.getReports
    },
    summary() {
      return this.$store.getters.getSummary
    },
    show: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
  },
  methods: {
    changeDate(date) {
      this.requestParams.from = date.validFrom
      this.requestParams.to = date.validTill
    },
    send() {
      this.$store.dispatch("loadReports", {
        id: this.editItem.id,
        from:this.requestParams.from,
        to:this.requestParams.to
      })
    },
    getFullReport() {
      this.$store.dispatch("loadPdfReport", {
        id: this.editItem.id,
        from:this.requestParams.from,
        to:this.requestParams.to
      })
    },
    onClose() {

      this.$store.dispatch("clearReports")
      this.show = false
    }
  }
}
</script>