<template>
    <v-col cols="12" sm="12">
        <v-card class="institute-saver-wrapper">
            <v-simple-table>
                <thead>
                <tr>
                    <th class="text-left">
                        Bezeichnung
                    </th>
                    <th class="text-left">
                        Wert
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Institute</td>
                    <td>
                        <v-select v-model="instituteID" :items="institutes" label="Institute" item-text="name"
                                  item-value="id" placeholder="Institute auswählen..."></v-select>
                    </td>
                </tr>
                <tr>
                    <td>Json Dateien</td>
                    <td>
                        <v-file-input v-model="files" @change="changeFiles" multiple accept=".json*"
                                      label="JSON for Scan"></v-file-input>
                    </td>
                </tr>
                <tr>
                    <td>Duplikate nicht hochladen</td>
                    <td>
                        <v-checkbox v-model="ignoreDuplicates"></v-checkbox>
                    </td>
                </tr>
                <tr>
                    <td>Benutzer Informieren</td>
                    <td>
                        <v-checkbox v-model="notifyUsers"></v-checkbox>
                    </td>
                </tr>
                <tr>
                    <td>Benachrichtigung Subject Erfolgreich</td>
                    <td>
                        <v-text-field :disabled="!notifyUsers" v-model="notifyUserSubject" label="Subject"
                                      placeholder="Subject eingeben...">
                        </v-text-field>
                    </td>
                </tr>
                <tr>
                    <td>Benachrichtigung Body Erfolgreich</td>
                    <td>
                        <v-text-field :disabled="!notifyUsers" v-model="notifyUserBody" label="Body"
                                      placeholder="Body eingeben...">
                        </v-text-field>
                    </td>
                </tr>
                <tr>
                    <td>Link</td>
                    <td>
                        <v-checkbox :disabled="!notifyUsers" v-model="linkEnabled"></v-checkbox>
                        <v-text-field :disabled="!notifyUsers || !linkEnabled" v-model="link" label="Body"
                                      placeholder="Link eingeben...">
                        </v-text-field>
                    </td>
                </tr>
                <tr>
                    <td>Platzhalter</td>
                    <td v-pre>Im Subject und Body können folgende Platzhalter verwendet werden:
                        "{{SCAN.CUSTOMER.FIRSTNAMES}}", "{{SCAN.CUSTOMER.LASTNAME}}", "{{SCAN.ID}}",
                        "{{SCAN.CUSTOMER.DOCUMENTNUMBER}}", "{{SCAN.CREATEDAT.DATE}}" und "{{SCAN.CREATEDAT.TIME}}"
                    </td>
                </tr>
                <tr>
                    <td>{{ scanBodys.length }} scans</td>
                    <td>
                        <v-row>
                            <v-btn @click="reset" :loading="loading" class="text--primary">Zurücksetzen
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn @click="send" :loading="loading" :disabled="!json || instituteID == null"
                                   class="primary">Anlegen
                            </v-btn>
                        </v-row>
                    </td>
                </tr>
                </tbody>
            </v-simple-table>
        </v-card>
    </v-col>
</template>

<script>
export default {
    data: () => ({
        instituteID: null,
        files: [],
        scanBodys: [],
        blocked: true,
        json: false,
        loading: false,
        token: false,
        linkEnabled: false,
        link: "https://lsn.goi-gp.f-i-verbund.de/scan/{{SCAN.ID}}",
        notifyUsers: false,
        notifyUserSubject: "Scan wiederhergestellt: {{SCAN.CUSTOMER.FIRSTNAMES}} {{SCAN.CUSTOMER.LASTNAME}}",
        notifyUserBody: "Scan nach Ausfall wiederhergestellt: {{SCAN.CREATEDAT.TIME}} {{SCAN.CREATEDAT.DATE}}",
        ignoreDuplicates: false
    }),

    methods: {
        reset() {
            this.loading = true
            this.files = []
            this.scanBodys = []
            this.json = false
            this.loading = false
            this.instituteID = false
            this.notifyUsers = false
            this.ignoreDuplicates = false

        },
        async send() {
            this.loading = true
            await this.$store.dispatch("createScans", {
                instituteID: this.instituteID,
                scans: this.scanBodys,
                notifyUsers: this.notifyUsers,
                notifyUserSubject: this.notifyUserSubject,
                notifyUserBody: this.notifyUserBody,
                ignoreDuplicates: this.ignoreDuplicates,
                link: this.link,
                linkEnabled: this.linkEnabled
            })
            this.files = []
            this.scanBodys = []
            this.json = false
            this.loading = false
        },
        changeFiles(files) {
            files.forEach(file => {
                this.jsonScanBody(file)
            })
        },
        jsonScanBody(scanBody) {
            const reader = new FileReader();
            reader.readAsText(scanBody);
            reader.onload = e => {
                this.scanBodys.push(JSON.parse(e.target.result))
                if (this.scanBodys.length === this.files.length) {
                    this.json = true
                }
            }
        }
    },
    computed: {
        institutes() {
            return this.$store.getters.getInstitutes
        },
    },
    created() {
        this.$store.dispatch("loadInstitutes")
    }
}
</script>