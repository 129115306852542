<template>
  <v-row justify="center">
    <v-dialog v-model="show" fullscreen hide-overlay :retain-focus="false" transition="dialog-bottom-transition"
      class="edit-institute-dialog">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click.stop="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Mandant bearbeiten {{editItem.id}}</v-toolbar-title>
          <v-spacer></v-spacer>

          <v-toolbar-items>
            <v-btn dark text @click="createRevision">
              Save
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-tabs v-model="tab" background-color="transparent" centered dark icons-and-text>
          <v-tabs-slider></v-tabs-slider>

          <v-tab @click="tab = 0" class="home-custom-tab">
            Basis
            <!--<v-icon>mdi-phone</v-icon>-->
          </v-tab>

          <v-tab @click="tab = 1" class="home-custom-tab">
            Details
            <!--<v-icon>mdi-heart</v-icon>-->
          </v-tab>

          <v-tab @click="tab = 2" class="home-custom-tab">
            Kontrollkriterien
            <!--<v-icon>mdi-heart</v-icon>-->
          </v-tab>

          <v-tab @click="tab = 3" class="home-custom-tab">
            Akzeptanzkriterien
            <!--<v-icon>mdi-heart</v-icon>-->
          </v-tab>
          <v-tab @click="tab = 4" class="home-custom-tab">
            Feldvalidierungen
            <!--<v-icon>mdi-heart</v-icon>-->
          </v-tab>

          <v-tab @click="tab = 5" class="home-custom-tab">
            Dokumenttypmapping
            <!--<v-icon>mdi-heart</v-icon>-->
          </v-tab>

          <v-tab @click="tab = 6" class="home-custom-tab">
            SVZ Leg
            <!--<v-icon>mdi-heart</v-icon>-->
          </v-tab>
          <v-tab @click="tab = 7" class="home-custom-tab">
            Auto Legitimierung
            <!--<v-icon>mdi-heart</v-icon>-->
          </v-tab>
          <v-tab @click="tab = 8" class="home-custom-tab">
            Reporting
          </v-tab>
        </v-tabs>
        <div class="tab-item-spacer"></div>
        <v-tabs-items v-model="tab">
          <v-tab-item :key=0>
            <v-card flat class="home-tab-outer-wrapper">
              <v-card flat>
                <div class="basic-config-wrapper">
                  <BasicConfig @newLogo="setNewLogo" :editItem="editItem"  :templates="[]"/>
                </div>
              </v-card>
            </v-card>
          </v-tab-item>
          <v-tab-item :key=1>
            <v-card flat class="home-tab-outer-wrapper">
              <v-card flat>
                <DetailsConfig :osp="editItem.osp" :mailing="editItem.mailing" :deletion="editItem.deletion"
                  :archiving="editItem.archiving" :svzLeg="editItem.svzLeg" :countries="countries"/>
              </v-card>
            </v-card>
          </v-tab-item>
          <v-tab-item :key=2>
            <v-card flat class="home-tab-outer-wrapper">
              <v-card flat>
                <ControlCriteria :controlChecks="editItem.controlChecks" :svzLeg="editItem.svzLeg" :countries="countries"/>
              </v-card>
            </v-card>
          </v-tab-item>
          <v-tab-item :key=3>
            <v-card flat class="home-tab-outer-wrapper">
              <v-card flat>
                <AcceptanceCriteria :acceptanceCriteria="editItem.acceptanceCriteria" :svzLeg="editItem.svzLeg" :countries="countries"/>
              </v-card>
            </v-card>
          </v-tab-item>
          <v-tab-item :key=4>
            <v-card flat class="home-tab-outer-wrapper">
              <v-card flat>
                <FieldValidation :fieldValidation="editItem.fieldValidation" :svzLeg="editItem.svzLeg" />
              </v-card>
            </v-card>
          </v-tab-item>
          <v-tab-item :key=5>
            <v-card flat class="home-tab-outer-wrapper">
              <v-card flat>
                <DocumentMapping :dtypeMapping="editItem.dtypeMapping" :svzLeg="editItem.svzLeg" :countries="countries" />
              </v-card>
            </v-card>
          </v-tab-item>
          <v-tab-item :key=6>
            <v-card flat class="home-tab-outer-wrapper">
              <v-card flat>
                <SVZLeg :svzLeg="editItem.svzLeg" />
              </v-card>
            </v-card>
          </v-tab-item>
          <v-tab-item :key=7>
            <v-card flat class="home-tab-outer-wrapper">
              <v-card flat>
                <AutoLegitimize :autoLegitimize="editItem.autoLegitimize" :mailing="editItem.mailing" />
              </v-card>
            </v-card>
          </v-tab-item>
          <v-tab-item :key=8>
            <v-card flat class="home-tab-outer-wrapper">
              <v-card flat>
                <Reporting :reporting="editItem.reporting"/>
              </v-card>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
    <app-revision-dialog :dialog="revisionDialog" @save="saveWithRevision"></app-revision-dialog>
  </v-row>
</template>

<script>
  import BasicConfig from "./institutConfig/BasicConfig"
  import DetailsConfig from "./institutConfig/DetailsConfig"
  import ControlCriteria from "./institutConfig/ControlCriteriaConfig"
  import AcceptanceCriteria from "./institutConfig/AcceptanceCriteria"
  import FieldValidation from "./institutConfig/FieldValidation"
  import DocumentMapping from "./institutConfig/DocumentMapping"
  import SVZLeg from "./institutConfig/SVZLeg"
  import AutoLegitimize from "./institutConfig/AutoLegitimizeConfig"
  import Reporting from "./institutConfig/ReportingConfig.vue"
  import AppRevisionDialog from "./Revision/RevisionDialog.vue"

  export default {
    data() {
      return {
        tab: 0,
        newLogo: false,
        revisionDialog:false
      }
    },
    props: {
      value: Boolean,
      editItem: null,
    },
    computed: {
      countries() {
        return this.$store.getters.getCountries
      },
      show: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
    },
    components: {
      BasicConfig,
      DetailsConfig,
      ControlCriteria,
      AcceptanceCriteria,
      FieldValidation,
      DocumentMapping,
      SVZLeg,
      AutoLegitimize,
      Reporting,
      AppRevisionDialog
    },
    methods: {
      setNewLogo(value) {
        this.newLogo = value
      },
      createRevision() {
        this.revisionDialog = true
      },
      saveWithRevision(revision){
        this.revisionDialog = false
        this.$store.dispatch("editinstitut", {institut:this.editItem, newLogo:this.newLogo,revision:revision})
      }
    }
  }
</script>

<style scoped>

  .tab-item-spacer {
    height: 50px;
  }



  .home-custom-tab {
    margin-top: 10px;
    background-color: #666;
    margin-right: 5px;
    margin-left: 5px;
    border-top: 20px solid #fff;
    width: 200px;
  }
</style>

<style>
  .v-tabs-slider-wrapper {
    color: red;
    height: 4px !important;
  }
</style>