<template>
    <v-container>
        <v-card class="institute-basic-wrapper">
            <v-simple-table>
                <template v-slot:default>
                    <thead>
                    <tr>
                        <th class="text-left">
                            Bezeichnung
                        </th>
                        <th class="text-left">
                            Wert
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Enabled Auto Legitimierung</td>
                        <td>
                            <v-checkbox v-model="autoLegitimize.autoLegitimizeEnabled"></v-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>Technischer User</td>
                        <td>
                            <v-text-field :disabled="!autoLegitimize.autoLegitimizeEnabled"
                                          v-model="autoLegitimize.username" label="Username"
                                          placeholder="Name eingeben...">
                            </v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Technischer User Password</td>
                        <td>
                            <v-text-field :disabled="!autoLegitimize.autoLegitimizeEnabled"
                                          v-model="autoLegitimize.password" type="password" label="Password"
                                          placeholder="Pass eingeben...">
                            </v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Email bei erfolgreichem Auto-Scan</td>
                        <td>
                            <v-checkbox v-model="autoLegitimize.mailOnSuccess"
                                        :disabled="!autoLegitimize.autoLegitimizeEnabled"></v-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>Mail Subject Erfolgreich</td>
                        <td>
                            <v-text-field
                                    :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.mailOnSuccess"
                                    v-model="autoLegitimize.mailSubjectSuccess" label="Subject"
                                    placeholder="Subject eingeben...">
                            </v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Mail Body Erfolgreich</td>
                        <td>
                            <html-editor
                                    :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.mailOnSuccess"
                                    v-model="autoLegitimize.mailBodySuccess"></html-editor>
                        </td>
                    </tr>
                    <tr>
                        <td>Platzhalter</td>
                        <td v-pre>Im Subject und Body können folgende Platzhalter verwendet werden:
                            "{{SCAN.CUSTOMER.FIRSTNAMES}}", "{{SCAN.CUSTOMER.LASTNAME}}", "{{SCAN.ID}}",
                            "{{SCAN.CUSTOMER.DOCUMENTNUMBER}}", "{{SCAN.CREATEDAT.DATE}}" und "{{SCAN.CREATEDAT.TIME}}"
                        </td>
                    </tr>
                    <tr>
                        <td>Email bei fehlgeschlagenem Auto-Scan</td>
                        <td>
                            <v-checkbox v-model="autoLegitimize.mailOnFailed"
                                        :disabled="!autoLegitimize.autoLegitimizeEnabled"></v-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>Mail Subject Fehlgeschlagen</td>
                        <td>
                            <v-text-field
                                    :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.mailOnFailed"
                                    v-model="autoLegitimize.mailSubjectFailed" label="Subject"
                                    placeholder="Subject eingeben...">
                            </v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Mail Body Fehlgeschlagen</td>
                        <td>
                            <html-editor
                                    :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.mailOnFailed"
                                    v-model="autoLegitimize.mailBodyFailed"></html-editor>
                        </td>
                    </tr>
                    <tr>
                        <td>Platzhalter</td>
                        <td v-pre>Im Subject und Body können folgende Platzhalter verwendet werden: "{{ERROR.MESSAGE}}"
                            "{{SCAN.CUSTOMER.FIRSTNAMES}}", "{{SCAN.CUSTOMER.LASTNAME}}", "{{SCAN.ID}}",
                            "{{SCAN.CUSTOMER.DOCUMENTNUMBER}}", "{{SCAN.CREATEDAT.DATE}}" und "{{SCAN.CREATEDAT.TIME}}"
                        </td>
                    </tr>
                    <tr>
                        <td>Benachrichtigung bei erfolgreichem Auto-Scan</td>
                        <td>
                            <v-checkbox v-model="autoLegitimize.notificationOnSuccess"
                                        :disabled="!autoLegitimize.autoLegitimizeEnabled"></v-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>Benachrichtigung Subject Erfolgreich</td>
                        <td>
                            <v-text-field
                                    :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.notificationOnSuccess"
                                    v-model="autoLegitimize.notificationSubjectSuccess" label="Subject"
                                    placeholder="Subject eingeben...">
                            </v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Benachrichtigung Body Erfolgreich</td>
                        <td>
                            <v-text-field
                                    :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.notificationOnSuccess"
                                    v-model="autoLegitimize.notificationBodySuccess" label="Body"
                                    placeholder="Body eingeben..."></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Link bei erfolgreichem Auto-Scan aktiviern</td>
                        <td>
                            <v-checkbox v-model="autoLegitimize.notificationLinkSuccessEnabled"
                                        :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.notificationOnSuccess"></v-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>Benachrichtigung Link Erfolgreich</td>
                        <td>
                            <v-text-field
                                :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.notificationOnSuccess || !autoLegitimize.notificationLinkSuccessEnabled"
                                v-model="autoLegitimize.notificationLinkSuccess" label="Link"
                                placeholder="Link eingeben..."></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Platzhalter</td>
                        <td v-pre>Im Subject und Body können folgende Platzhalter verwendet werden:
                            "{{SCAN.CUSTOMER.FIRSTNAMES}}", "{{SCAN.CUSTOMER.LASTNAME}}", "{{SCAN.ID}}",
                            "{{SCAN.CUSTOMER.DOCUMENTNUMBER}}", "{{SCAN.CREATEDAT.DATE}}" und "{{SCAN.CREATEDAT.TIME}}"
                        </td>
                    </tr>
                    <tr>
                        <td>Benachrichtigung bei fehlgeschlagenem Auto-Scan</td>
                        <td>
                            <v-checkbox v-model="autoLegitimize.notificationOnFailed"
                                        :disabled="!autoLegitimize.autoLegitimizeEnabled"></v-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>Benachrichtigung Subject Fehlgeschlagen</td>
                        <td>
                            <v-text-field
                                    :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.notificationOnFailed"
                                    v-model="autoLegitimize.notificationSubjectFailed" label="Subject"
                                    placeholder="Subject eingeben...">
                            </v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Benachrichtigung Body Fehlgeschlagen</td>
                        <td>
                            <v-text-field
                                    :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.notificationOnFailed"
                                    v-model="autoLegitimize.notificationBodyFailed" label="Body"
                                    placeholder="Body eingeben..."></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Link bei fehlerhaftem Auto-Scan aktiviern</td>
                        <td>
                            <v-checkbox v-model="autoLegitimize.notificationLinkFailedEnabled"
                                        :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.notificationOnSuccess"></v-checkbox>
                        </td>
                    </tr>
                    <tr>
                        <td>Benachrichtigung Link Fehlgeschlagen</td>
                        <td>
                            <v-text-field
                                :disabled="!autoLegitimize.autoLegitimizeEnabled || !autoLegitimize.notificationOnSuccess || !autoLegitimize.notificationLinkFailedEnabled"
                                v-model="autoLegitimize.notificationLinkFailed" label="Link"
                                placeholder="Link eingeben..."></v-text-field>
                        </td>
                    </tr>
                    <tr>
                        <td>Platzhalter</td>
                        <td v-pre>Im Subject und Body können folgende Platzhalter verwendet werden: "{{ERROR.MESSAGE}}"
                            "{{SCAN.CUSTOMER.FIRSTNAMES}}", "{{SCAN.CUSTOMER.LASTNAME}}", "{{SCAN.ID}}",
                            "{{SCAN.CUSTOMER.DOCUMENTNUMBER}}", "{{SCAN.CREATEDAT.DATE}}" und "{{SCAN.CREATEDAT.TIME}}"
                        </td>
                    </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </v-card>
    </v-container>
</template>

<script>
import {VueEditor} from "vue2-editor";

export default {
    props: {
        autoLegitimize: {},
        mailing: {}
    },
    data() {
        return {
            mailProtocol: [
                "SMTP", "SMTPS"
            ]
        }
    },
    created() {
    },
    components: {
        htmlEditor: VueEditor
    },

    computed: {},

    methods: {},
}
</script>

<style scoped>
.institute-basic-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 15px;
    margin-bottom: 40px;

}

.institute-basic-wrapper:hover {
    box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
}
</style>