<template>
  <div>
    <v-data-table :headers="headers" sort-by="created_at" :items="items" sort-desc :search="search"
                  class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line
                        hide-details></v-text-field>

          <v-spacer></v-spacer>
          <EditDialog v-model=editDialog :editItem="editedItem"/>
          <report-dialog v-model=reportDialog :editItem="editedItem"/>
          <scans-dialog v-model=scansDialog :editItem="editedItem" :scans="scans"/>
          <CreateDialog v-model=createDialog :editItem="editedItem" :templates="templates"/>
          <v-btn color="primary" class="mr-2" @click="createItem">
            <v-icon>mdi-domain-plus</v-icon>
          </v-btn>
          <v-dialog v-model="dialogDelete" max-width="600px">
            <v-card>
              <v-card-title class="headline">Sind sie sich sicher dass sie diesen Eintrag löschen
                wollen?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="dialogDelete = false">Abbrechen</v-btn>
                <v-btn color="primary" text @click="deleteItemConfirm">Löschen</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-spacer></v-spacer>
          <v-btn @click="history()" icon>
            <v-icon small class="mr-2">
              fa-history
            </v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="reportItem(item)">
          mdi-clipboard-edit-outline
        </v-icon>
        <v-icon small class="mr-2" @click="showItemScans(item)">
          mdi-id-card
        </v-icon>
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small class="mr-2" @click="deleteItem(item)">
          mdi-delete
        </v-icon>
        <v-btn :to="{name:'Svz',params:{instituteID:item.id}}" icon>
          <v-icon small class="mr-2">
            mdi-key-chain-variant
          </v-icon>
        </v-btn>
        <v-btn @click="historyItem(item)" icon>
          <v-icon small class="mr-2">
            fa-history
          </v-icon>
        </v-btn>
      </template>
      <template v-slot:item.logo="{ item }">
        <div style="display:flex; padding: 10px;">
          <img v-bind:src="item.logo" style="width: auto; height: 100px; margin:auto"/>
        </div>
      </template>
      <template v-slot:no-data>
        <v-data-table item-key="name" class="elevation-1" loading loading-text="Daten werden geladen"
                      :hide-default-footer="true"></v-data-table>
      </template>
    </v-data-table>
    <v-overlay :value="overlay">
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <app-revision-dialog :dialog="revisionDialog" @save="deleteWithRevision"></app-revision-dialog>

  </div>
</template>

<script>
import EditDialog from './EditInstitutDialog.vue'
import CreateDialog from './CreateInstitutDialog.vue'
import ReportDialog from './ReportDialog.vue'
import ScansDialog from './Scans/Rows.vue'
import AppRevisionDialog from "./Revision/RevisionDialog.vue";

export default {
  components: {
    AppRevisionDialog,
    EditDialog,
    CreateDialog,
    ReportDialog,
    ScansDialog
  },
  data: () => ({
    overlay: false,
    dialog: false,
    editDialog: false,
    createDialog: false,
    dialogDelete: false,
    scansDialog: false,
    itemToDelete: null,
    reportDialog: false,
    revisionDialog:false,
    search: '',
    headers: [{
      text: 'Logo',
      align: 'start',
      sortable: false,
      value: 'logo',
    },
      {
        text: 'Name',
        value: 'name'
      },
      {
        text: 'Region',
        value: 'region'
      },
      {
        text: 'Instituts-Nr.',
        value: 'institute_nr'
      },
      {
        text: 'VRZ',
        value: 'vrz'
      },
      {
        text: 'Status',
        value: 'status'
      },
      {
        text: '',
        value: 'actions',
        sortable: false
      },
    ],
    editedItem: {
      id: "",
      name: "",
      region: "",
      institute_nr: "",
      vrz: "",
      instituteToken: "",
      logo: null,
      isTemplate: null,
      updateGroup: "",
      template: "",
      status: "",
      osp: null,
      mailing: null,
      autoLegitimize: null,
      deletion: null,
      archiving: null,
      controlChecks: null,
      acceptanceCriteria: null,
      fieldValidation: null,
    },
    defaultItem: {
      id: "",
      name: "",
      region: "",
      institute_nr: "",
      vrz: "",
      instituteToken: "",
      logo: null,
      isTemplate: null,
      updateGroup: "",
      template: "",
      status: "",
      osp: null,
      mailing: null,
      autoLegitimize: null,
      deletion: null,
      archiving: null,
      controlChecks: null,
      acceptanceCriteria: null,
      fieldValidation: null,
    },
  }),

  created() {
    this.initialize()
  },
  computed: {
    items() {
      return this.$store.getters.getInstitutes
    },
    templates() {
      return this.$store.getters.getTemplates
    },
    scans() {
      return this.$store.getters.getScans
    }
  },

  methods: {
    historyItem(item) {
      this.$router.push({
        name: 'RevisionForId',
        params: {
          className: "Institute",
          id: item.id
        },
        query: {
          caller: this.$route.name
        }
      })
    },
    history() {
      this.$router.push({
        name: 'Revisions',
        params: {
          className: "Institute"
        },
        query: {
          caller: this.$route.name
        }
      })
    },
    initialize() {
      this.$store.dispatch("loadCountries")
      this.$store.dispatch("loadInstitutes")
    },

    editItem(item) {
      this.editedItem = item
      this.editDialog = true
    },

    showItemScans(item) {
      this.overlay = true
      this.editedItem = item
      var store = this.$store
      this.$store.dispatch("clearScans").then(() => {
        store.dispatch("loadScans", item.id).then(() => {
          this.scansDialog = true
          this.overlay = false
        })
      })

    },

    reportItem(item) {
      this.editedItem = item
      this.reportDialog = true
    },

    createItem() {
      this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
      this.createDialog = true
    },

    deleteItem(item) {
      this.itemToDelete = item
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.dialogDelete = false
      this.revisionDialog = true
    },
    deleteWithRevision(revision){
      this.dialogDelete = false
      this.revisionDialog = false
      this.$store.dispatch("deleteinstitut", {institut:this.itemToDelete,revision:revision})
    },
    save() {

    },
  },
}
</script>