<template>
    <v-data-table :headers="headers" :items="fieldValidation" :search="search" class="elevation-1">
        <template v-slot:top>
            <v-toolbar flat>
                <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line
                              hide-details></v-text-field>
                <v-spacer></v-spacer>
                <v-dialog v-model="dialog" max-width="500px">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                            Neuer Eintrag
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title>
                            <span class="headline">{{ formTitle }}</span>
                        </v-card-title>

                        <v-card-text>
                            <v-container>
                                <v-row>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-combobox v-model="editedItem.field" :items="fieldOptions" label="Feld"
                                                    placeholder="Feld auswählen..."></v-combobox>

                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-combobox v-model="editedItem.personType" :items="personOptions"
                                                    label="Personenart"
                                                    placeholder="Personenart auswählen..."></v-combobox>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-combobox v-model="editedItem.operator" :items="operatorOptions"
                                                    label="Operator"
                                                    placeholder="Operator auswählen..."></v-combobox>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                        <v-text-field v-model="editedItem.comparative"
                                                      label="Komparator"></v-text-field>
                                    </v-col>
                                    <v-col cols="12" sm="12" md="6">
                                            <v-autocomplete :return-object="false" v-model="editedItem.documentType" item-text="shortDesc" item-value="id" :items="svzLegFiltered"
                                                label="Dokument Typ"
                                                placeholder="Dokument Typ auswählen..."></v-autocomplete>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="close">
                                Abbrechen
                            </v-btn>
                            <v-btn color="primary" text @click="save">
                                Speichern
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-dialog v-model="dialogDelete" max-width="600px">
                    <v-card>
                        <v-card-title class="headline">Wollen Sie diesen Eintrag wirklich löschen?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="primary" text @click="closeDelete">Abbrechen</v-btn>
                            <v-btn color="primary" text @click="deleteItemConfirm">löschen</v-btn>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-toolbar>
        </template>
        <template v-slot:item.documentType="{ item }">
            {{showSVZValue(item.documentType)}}
        </template>
        <template v-slot:item.actions="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
                mdi-delete
            </v-icon>
        </template>
    </v-data-table>
</template>

<script>
export default {
    data: () => ({
        dialog: false,
        dialogDelete: false,
        search: "",
        headers: [{
            text: 'Feld',
            align: 'start',
            value: 'field',
        },
            {
                text: 'Operator',
                value: 'operator'
            },
            {
                text: 'Komperator',
                value: 'comparative'
            },
            {
                text: 'Personenart',
                value: 'personType'
            },
            {
                text: 'Dokumententyp',
                value: 'documentType'
            },
            {
                text: '',
                value: 'actions',
                sortable: false
            },
        ],
        fieldOptions: [
            "firstNames", "lastName", "dateOfBirth", "placeOfBirth", "documentNumber", "issuer", "issuerCountry",
            "issuedAt", "validUntil", "nationality","birthName"
        ],
        personOptions: [
            "NP", "JP"
        ],
        operatorOptions: [
            "EQUALS", "CONTAINS", "MIN_AGE", "MAX_AGE", "NOT_EMPTY", "NOT_NULL", "NOT_IN_PAST", "NOT_IN_FUTURE"
        ],
        editedIndex: -1,
        editedItem: {
            field: '',
            operator: "",
            comparative: "",
            personType: "",
        },
        defaultItem: {
            field: '',
            operator: "",
            comparative: "",
            personType: "",
        },
    }),

    props: {
        fieldValidation: null,
        svzLeg:{required: true, type: Array},
    },

    computed: {
        formTitle() {
            return this.editedIndex === -1 ? 'Neuen Eintrag erstellen' : 'Eintrag bearbeiten'
        },
        svzLegFiltered(){
            return this.svzLeg.filter(x => x.active === true)
        }
    },

    watch: {
        dialog(val) {
            val || this.close()
        },
        dialogDelete(val) {
            val || this.closeDelete()
        },
    },
    methods: {
        showSVZValue(value){
            if(value == null || value === ""|| value ===undefined){
                return ""
            }
            const type = this.svzLegFiltered.filter(x => x.id === value);
            if(type.length === 0 ){
                return "FEHLER (Nicht mehr aktiv oder gelöscht, unbedingt berichtigen!)"
            }
            else{
                return type[0].shortDesc
            }
        },
        deduplicateArrayByValue(array, key) {
            const seen = new Set();
            return array.filter((item) => {
                const value = item[key];
                if (!seen.has(value)) {
                    seen.add(value);
                    return true;
                }
                return false;
            });
        },
        editItem(item) {
            this.editedIndex = this.fieldValidation.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },

        deleteItem(item) {
            this.editedIndex = this.fieldValidation.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialogDelete = true
        },

        deleteItemConfirm() {
            this.fieldValidation.splice(this.editedIndex, 1)
            this.closeDelete()
        },

        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        closeDelete() {
            this.dialogDelete = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        save() {
            if (this.editedIndex > -1) {
                Object.assign(this.fieldValidation[this.editedIndex], this.editedItem)
            } else {
                this.fieldValidation.push(this.editedItem)
            }
            this.close()
        },
    },
}
</script>