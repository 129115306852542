<template>
  <div>
    <v-data-table :headers="headers" :items="items" item-key="name" class="elevation-1" :search="search">
      <template v-slot:top>
        <v-toolbar flat>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Suchen" single-line hide-details>
          </v-text-field>
          <v-spacer></v-spacer>
          <v-btn @click="history()" icon>
            <v-icon small class="mr-2">
              fa-history
            </v-icon>
          </v-btn>
        </v-toolbar>

      </template>
      <template v-slot:no-data>
        <v-data-table item-key="name" class="elevation-1" loading loading-text="Daten werden geladen"
                      :hide-default-footer="true"></v-data-table>
      </template>
      <template v-slot:item.scans="{ item }">
        <img v-bind:src="item.images[0]" style="width: auto; height: 100px"
             @click="triggerImageDialog(item.images)"/>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="accept(item)" v-if="!item.approved">
          mdi-check-bold
        </v-icon>
        <v-icon small class="mr-2" @click="triggerDeleteDialog(item)">
          mdi-delete
        </v-icon>
        <v-dialog v-model="deleteDialog" width="500" :retain-focus="false">
          <v-card>
            <v-card-title style="color:white" class="primary">
              Eintrag löschen
            </v-card-title>

            <v-card-text>
              Wollen Sie den ausgewählten Eintrag wirklich löschen?
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deleteDialog = false">
                Abbrechen
              </v-btn>
              <v-btn color="primary" text @click="deleteScan()">
                Löschen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn @click="historyItem(item)" icon>
          <v-icon small class="mr-2">
            fa-history
          </v-icon>
        </v-btn>
      </template>
      <template>
        <v-icon small color="green">
          mdi-check-bold
        </v-icon>
      </template>
    </v-data-table>
    <template>
      <div class="text-center">
        <v-dialog v-model="imageDialog" height="unset" width="unset">
          <v-card>
            <template>
              <v-carousel hide-delimiter-background show-arrows-on-hover height="auto" width="auto">
                <div>
                  <v-carousel-item v-for="(item,i) in slides" :key="i" :src="item"
                                   reverse-transition="fade-transition" transition="fade-transition"></v-carousel-item>
                </div>

              </v-carousel>
            </template>
          </v-card>
        </v-dialog>
      </div>
    </template>
    <app-revision-dialog :dialog="revisionAddDialog" @save="acceptWithRevision"></app-revision-dialog>
    <app-revision-dialog :dialog="revisionDeleteDialog" @save="deleteWithRevision"></app-revision-dialog>
  </div>
</template>

<script>
import AppRevisionDialog from "./Revision/RevisionDialog.vue";

export default {
  components: {AppRevisionDialog},
  data() {
    return {
      revisionAddDialog:false,
      revisionAddItem:null,
      revisionDeleteDialog:false,
      revisionDeleteItem:null,
      slides: [],
      search: '',
      imageDialog: false,
      deleteDialog: false,
      headers: [{
        text: 'Scans',
        value: 'scans'
      },
        {
          text: 'Behördenkennung',
          value: 'issuerCode'
        },
        {
          text: 'Behördenname',
          value: 'issuerName'
        },
        {
          text: '',
          align: 'end',
          value: 'actions',
          sortable: false
        },
      ],
    }
  },
  created() {
    this.$store.dispatch("loadIssueres")
  },
  computed: {
    items() {
      return this.$store.getters.getIssueres
    },
  },
  methods: {
    acceptWithRevision(revision){
      this.$store.dispatch("editIssuer", {issuerMapping:this.revisionDeleteDialog,revision});
      this.revisionAddDialog = false
    },
    deleteWithRevision(revision){

      this.$store.dispatch("deleteIssuer", {issuerMapping:this.revisionDeleteItem,revision});
      this.deleteDialog = false
      this.revisionDeleteDialog = false

    },
    historyItem(item) {
      this.$router.push({
        name: 'RevisionForId',
        params: {
          className: "IssuerMapping",
          id: item.id
        },
        query: {
          caller: this.$route.name
        }
      })
    },
    history() {
      this.$router.push({
        name: 'Revisions',
        params: {
          className: "IssuerMapping"
        },
        query: {
          caller: this.$route.name
        }
      })
    },
    accept(item) {
      this.revisionAddItem = item
      this.revisionAddDialog = true
    },
    // eslint-disable-next-line no-unused-vars
    decline(item) {
    },
    deleteScan() {
      this.revisionDeleteDialog = true

    },
    triggerDeleteDialog(item) {
      this.revisionDeleteItem = item
      this.deleteDialog = true
    },
    triggerImageDialog(images) {
      this.slides = images
      this.imageDialog = true
    }
  }
}
</script>