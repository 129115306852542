<template>
    <v-card elevation="2" class="settings-cardview">
        <template>
            <v-simple-table>
                <template v-slot:default>
                    <tbody>
                        <tr>
                            <td>Passwort ändern:</td>
                            <td>
                                <v-text-field v-model="password" label="Passwort" :rules="rules" hide-details="auto"></v-text-field>
                            </td>
                            <td>
                                <v-btn color="primary" @click="savePassword">Speichern</v-btn>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
        </template></v-card>
</template>

<script>
    export default {
        data: () => ({
            password: "",
            rules: [
                value => !!value || 'Benötigt',
                value => (value && value.length >= 3) || 'Min 3 Zeichen',
            ],
        }),
        props:{
            userID: null,
        },
        methods: {
            savePassword(){
                this.$store.dispatch("editPassword", {data:this.$store.getters.getUser, password:this.password})
            }
        }
    }
</script>

<style scoped>
    .settings-cardview {
        margin-top: 20px;
        max-width: 930px;
        margin-right: auto;
        margin-left: auto;
    }
</style>