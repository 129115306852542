<template>
    <v-container>
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-card class="institute-saver-wrapper">
                    <v-simple-table>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Bezeichnung
                            </th>
                            <th class="text-left">
                                Wert
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Historisierung von früheren Legitimationen</td>
                            <td>
                                <v-combobox v-model="osp.archiveOldLegitimation" :items="archiveOldLegitimation"
                                            label="Historisierung" placeholder="Option auswählen..."></v-combobox>
                            </td>
                        </tr>
                        <tr>
                            <td>Personensuche Ertweitern</td>
                            <td>
                                <v-checkbox v-model="osp.extendPersonSearch"></v-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td>Vorgehen bei Vornamen</td>
                            <td>
                                <v-combobox v-model="osp.setName" :items="firstNamesOptions" label="Vornamen"
                                            placeholder="Option auswählen..."></v-combobox>

                            </td>
                        </tr>
                        <tr>
                            <td>Geburtsort aktualisieren</td>
                            <td>
                                <v-checkbox v-model="osp.updatePlaceOfBirth"></v-checkbox>
                            </td>
                        </tr>
                        <tr>
                            <td>Prüfung „Ausstellende Behörde“</td>
                            <td>
                                <v-checkbox v-model="osp.updateIssuer"></v-checkbox>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-card class="institute-deletion-wrapper">
                    <v-simple-table>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Bezeichnung
                            </th>
                            <th class="text-left">
                                Wert
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Löschung von bearbeiteten Legitimationen nach x Tagen</td>
                            <td>
                                <v-text-field v-model="deletion.deleteCompletedAfter" type="number" label="Tage">
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>Löschung von unbearbeiteten Legitimationen nach x Tagen</td>
                            <td>
                                <v-text-field v-model="deletion.deleteNewAfter" type="number" label="Tage">
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>Löschung von gelöschten Legitimationen nach x Tagen</td>
                            <td>
                                <v-text-field v-model="deletion.deleteDeletedAfter" type="number" label="Tage">
                                </v-text-field>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <v-card class="institute-archive-wrapper">
                    <v-simple-table>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Bezeichnung
                            </th>
                            <th class="text-left">
                                Wert
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Addressdaten verfahren</td>
                            <td>
                                <v-combobox v-model="osp.considerAddress" :items="considerAddressItems"
                                            label="Addressdaten"
                                            placeholder="Verfahren wählen"></v-combobox>
                            </td>
                        </tr>
                        <tr>
                            <td>Interessent erstellen</td>
                            <td>
                                <v-checkbox v-model="osp.potentialCustomerCreate"></v-checkbox>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                    <v-card class="institute-control-document-wrapper">
                        <v-card-title>Adressdaten kriterien</v-card-title>
                        <v-divider></v-divider>
                        <v-data-table :headers="docHeaders" :items="osp.considerAddressCriteria" :search="docsSearch"
                                      class="institute-control-table">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-text-field v-model="docsSearch" append-icon="mdi-magnify" label="Suchen"
                                                  single-line
                                                  hide-details></v-text-field>

                                    <v-spacer></v-spacer>
                                    <v-btn color="primary" class="mr-2" @click="createDocItem">
                                        <v-icon>mdi-text-box-plus-outline</v-icon>
                                    </v-btn>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="editDocItem(item)">
                                    mdi-pencil
                                </v-icon>
                                <v-icon small @click="deleteDocItem(item)">
                                    mdi-delete
                                </v-icon>
                            </template>
                            <template v-slot:item.logo="{ item }">
                                <img v-bind:src="item.logo" style="width: auto; height: 100px"/>
                            </template>
                        </v-data-table>
                    </v-card>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="12">
                <v-card class="institute-archive-wrapper">
                    <v-simple-table>
                        <thead>
                        <tr>
                            <th class="text-left">
                                Bezeichnung
                            </th>
                            <th class="text-left">
                                Wert
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td>Verzeichnis für Archivierung</td>
                            <td>
                                <v-text-field v-model="archiving.path" type="text" label="Pfad">
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>Dateiendung für Archivierung</td>
                            <td>
                                <v-text-field v-model="archiving.fileFormat" type="text" label="Endung">
                                </v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>Dateiname für Archivierung</td>
                            <td>
                                <v-text-field v-model="archiving.specialArchiveName" type="text"
                                              label="Dateiname für Archivierung"></v-text-field>
                            </td>
                        </tr>
                        <tr>
                            <td>Begleitdatei erstellen</td>
                            <td>
                                <v-checkbox v-model="archiving.createCompanionFile"></v-checkbox>
                            </td>
                        </tr>
                        </tbody>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" sm="12" md="6">
                <v-card class="institute-comment-wrapper">
                    <v-card-title>XSLT</v-card-title>
                    <v-divider></v-divider>
                    <v-textarea v-model="archiving.xslt" solo class="institute-comment-input"></v-textarea>
                </v-card>
            </v-col>
            <v-col cols="12" sm="12" md="6">
                <v-card class="legitimationsbemerkungen-comment-wrapper">
                    <v-card-title>Legitimationsbemerkungen</v-card-title>
                    <v-divider></v-divider>
                    <v-textarea v-model="osp.legitimizeComment" solo class="legitimationsbemerkungen-comment-input">
                    </v-textarea>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog v-model="deleteDialog" max-width="600px">
            <v-card>
                <v-card-title class="headline">Sind sie sich sicher dass sie diesen Eintrag löschen wollen?
                </v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="deleteDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="deleteItemConfirm">Löschen</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="editDocDialog" max-width="600px">
            <v-card>
                <v-card-title class="headline">Dokument bearbeiten</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-combobox v-model="newDoc.issuerCountry" :items="countries" item-value="icao3"
                                            item-text="vollform" :return-object="false" hide-selected
                                            label="Ausstellungs Land">
                                </v-combobox>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">
                                <v-combobox v-model="newDoc.docType" :items="svzLegFiltered" item-value="ospDocType"
                                            item-text="shortName" :return-object="false" hide-selected
                                            label="Dokumentenart">
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="editDocDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="saveEditedDocItem">Speichern</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="createDocDialog" max-width="600px">
            <v-card>
                <v-card-title class="headline">Dokument erstellen</v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="12" md="6">
                                <v-combobox v-model="newDoc.issuerCountry" :items="countries" item-value="icao3"
                                            item-text="vollform" :return-object="false" hide-selected
                                            label="Ausstellungs Land">
                                </v-combobox>
                            </v-col>
                            <v-col cols="12" sm="12" md="6">

                                <v-combobox v-model="newDoc.docType" :items="svzLegFiltered" item-value="ospDocType"
                                            item-text="shortName" :return-object="false" hide-selected
                                            label="Dokumentenart">
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="createDocDialog = false">Abbrechen</v-btn>
                    <v-btn color="primary" text @click="saveNewDocItem">Speichern</v-btn>
                    <v-spacer></v-spacer>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            editedIndex: -1,
            docsSearch: null,
            considerAddressItems: [
                "DISABLED", "COMPARE", "COMPARE_UPDATE"
            ],
            archiveOldLegitimation: [
                "DISABLED", "ENABLED", "ENABLED_EXPIRED"
            ],
            firstNamesOptions: [
                "DISABLED", "MINUS", "UPDATE_OR_MINUS"
            ],
            docHeaders: [{
                text: 'Ausstellendes Land',
                align: 'start',
                value: 'issuerCountry',
            }, {
                text: 'Dokumentenart',
                align: 'start',
                value: 'docType',
            }, {
                text: 'Actionen',
                value: 'actions',
            }],
            newDoc: {
                docType: "",
                issuerCountry: null,
            },
            createDocDialog: false,
            editDocDialog: false,
            deleteDialog: false
        }
    },
    created() {
    },
    props: {
        osp: null,
        mailing: null,
        deletion: null,
        archiving: null,
        countries: null,
        svzLeg: {required: true, type: Array},
    },
    methods: {
        saveEditedDocItem() {
            this.osp.considerAddressCriteria[this.editedIndex] = this.newDoc
            this.editDocDialog = false
            this.createDocDialog = false
        },
        deleteItemConfirm() {
            this.osp.considerAddressCriteria.splice(this.editedIndex, 1)
            this.deleteDialog = false
        },
        createDocItem() {
            this.createDocDialog = true
        },
        editDocItem(item) {
            this.editedIndex = this.osp.considerAddressCriteria.indexOf(item)
            this.newDoc = item
            this.editDocDialog = true
        },
        saveNewDocItem() {
            this.osp.considerAddressCriteria.push(JSON.parse(JSON.stringify(this.newDoc)))
            this.newDoc = {
                docType: "",
                issuerCountry: null,
            }
            this.editDocDialog = false
            this.createDocDialog = false
        },
        deleteDocItem(item) {
            this.editedIndex = this.osp.considerAddressCriteria.indexOf(item)
            this.tableNr = 0
            this.deleteDialog = true
        },
    },
    computed: {
        svzLegFiltered() {
            return this.svzLeg.filter(x => x.active === true)
        }
    }
}
</script>

<style scoped>
.institute-saver-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 15px;
    height: 100%;
}

.institute-deletion-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 15px;
    height: 100%;
}

.institute-connection-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 15px;
    height: 100%;
}

.institute-archive-wrapper {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3);
    padding: 15px;
    height: 100%;
}

.institute-comment-input {
    margin: 15px;
}

.legitimationsbemerkungen-comment-input {
    margin: 15px;
}

.institute-deletion-wrapper:hover,
.institute-saver-wrapper:hover,
.institute-archive-wrapper:hover,
.institute-connection-wrapper:hover,
.institute-comment-wrapper:hover,
.legitimationsbemerkungen-comment-wrapper:hover {
    box-shadow: 0 4px 16px 0 rgba(255, 0, 0, 0.3);
}
</style>