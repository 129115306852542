<template>
  <div class="text-center">
    <v-dialog
      v-model="show"
      width="auto"
    >
      <v-card>
        <v-card-title class="primary mini-dialog-title">
          Mehrere Personen gefunden
        </v-card-title>

        <v-divider></v-divider>
            <div class="not-found-item-wrapper" 
                      v-for="item in persons"
                      :key="item.firstName">
                <div class="not-found-label-outer-wrapper">
                  <div class="not-found-item-inner-wrapper">
                    <v-subheader>{{item.firstName}} {{item.lastName}}</v-subheader>
                  </div>
                </div>
                <div class="not-found-birth-outer-wrapper">
                  <div class="not-found-item-inner-wrapper">
                    <v-subheader>{{item.birthday}}</v-subheader>
                  </div>
                </div>
                <div class="not-found-input-outer-wrapper">
                  <div class="not-found-item-inner-wrapper">
                    <v-subheader>{{item.personNumber}}</v-subheader>
                  </div>
                </div>
                <div class="not-found-button-outer-wrapper">
                  <div class="not-found-item-inner-wrapper">
                    <v-btn
                      color="primary"
                      text
                      @click="getPerson(item)"
                    >
                      Auswählen
                    </v-btn>
                  </div>
                </div>
              </div>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        dialog: false,
      }
    },
    props: {
      value: Boolean,
      persons: {},
    },
    computed: {
      show: {
        get () {
          return this.value
        },
        set (value) {
          this.$emit('input', value)
        }
      },
    },
    methods:{
      getPerson(person){
        this.$store.dispatch("loadDisplayOspperson",person.personNumber)
        this.show = false
      }
    }
  }
</script>

<style scoped>
.not-found-item-wrapper{
  display: flex;
  justify-content: space-between;
}
.not-found-label-outer-wrapper, .not-found-input-outer-wrapper, .not-found-button-outer-wrapper, .not-found-birth-outer-wrapper {
  width: 25%;
}
.not-found-input-outer-wrapper{
  display: flex;
  justify-content: center;
}
.not-found-button-outer-wrapper{
  display: flex;
  justify-content: flex-end;
}
.mini-dialog-title{
  color: white;
}
</style>